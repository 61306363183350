
import domains from '@/services/domains';

export default {
  data() {
    return {
      configuration: true,
      domain: '',
      loadingBtn: false
    };
  },
  watch: {
    configuration(newValue) {
      if (!newValue) {
        this.$emit('closeModel', false);
      }
    }
  },
  methods: {
    isValidDomain(domain) {
      const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,6}$/;

      if (!domainPattern.test(domain)) {
        // this.$q.notify({
        //   message: this.$store.state.user.translate.use_domain,
        //   color: 'red'
        // });
        return false;
      }
      return true;
    },

    createDomain() {
      if (!this.isValidDomain(this.domain)) {
        this.$q.notify({
          message: this.$store.state.user.translate.use_domain,
          color: 'red'
        });
        return;
      }

      this.loadingBtn = true;
      domains
        .createWhiteListDomain(this.domain)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate
              .domain_created_successfully,
            color: 'green'
          });
          this.loadingBtn = false;
          this.$emit('updateWhitelistDomains');
        })
        .catch(error => {
          const errorMessage =
            error.response?.data?.message ||
            this.$store.state.user.translate.something_went_wrong;

          this.$q.notify({
            message: errorMessage,
            color: 'red'
          });

          this.loadingBtn = false;
          this.$emit('closeModel', false);
        });
    }
  }
};
