
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import LoadingSpinner from '@/components/Common/Loading/LoadingSpinner.vue';
import ChartPie from '@/components/Common/Chart/Pie/ChartPie.vue';
import ChartPieValue from '@/components/Common/Chart/Pie/ChartPieValue.vue';
import BoxHeader from '@/components/Box/Common/Header/BoxHeader.vue';
import { MULTI_COLORS, getLabelTransMethod } from '@/util/colors';
import store from '@/store';

export default Vue.extend({
  components: { LoadingSpinner, BoxHeader, ChartPie, ChartPieValue },
  // eslint-disable-next-line vue/require-prop-types
  props:["zone"],
  data() {
    return {
      loading: false,
      seriesPer: [],
      series: [],
      labels: [],
      colors: [],
      periodType: 'value'
    };
  },

  computed: {
    ...mapGetters('user', ['getLocale']),
    ...mapState('demographics', ['demoGroup', 'demoGroupValue'])
  },

  watch: {
    zone: _.debounce(function() {
      this.loadData();
    }, 300),
    periodType: _.debounce(function() {
      this.loadData();
    }, 300)
  },
  methods: {
    loadData() {
      this.loading = true;
      const storeState: any = store.state;
      const translate = storeState.user.translate;
      const id = storeState.user.profile.tenant._id;

        if (this.periodType == 'percentage') {
        //   data = this.demoGroup;
          const cCount = {};
          Object.values(this.zone || {}).forEach(({ summary: { dm } }) =>
            Object.entries(dm).forEach(([key, value]) => (cCount[key] = (cCount[key] || 0) + value))
          );
          const sum = ['1', '2', '3', '4','5', '6', '7', '8']?.map(key => cCount[key] || 0);
          this.seriesPer = sum;
          const totalVal = this.seriesPer?.reduce((sum, value) => {
            return (
              sum + (typeof value === 'number' && !isNaN(value) ? value : 0)
            );
          }, 0);

          const percentages =
            totalVal > 0
              ? this.seriesPer
                  .map(
                    value =>
                      ((typeof value === 'number' && !isNaN(value)
                        ? value
                        : 0) /
                        totalVal) *
                      100
                  )
                  .map(p => parseFloat(p.toFixed(2)))
              : this.seriesPer.map(() => 0);

          this.seriesPer = percentages;
          this.series = null;
        } else {
          const cCount = {};
          Object.values(this.zone || {}).forEach(({ summary: { dm } }) =>
            Object.entries(dm).forEach(([key, value]) => (cCount[key] = (cCount[key] || 0) + value))
          );
          // data = Object.values(cCount);
          const sum = ['1', '2', '3', '4','5', '6', '7', '8']?.map(key => cCount[key] || 0);
          this.series = sum;
          //   data = this.demoGroupValue;
          this.seriesPer = null;
        }

        this.labels[0] = translate[`male_child_${id}`]
          ? translate[`male_child_${id}`]
          : translate['male_child'];
        this.labels[1] = translate[`male_young_${id}`]
          ? translate[`male_young_${id}`]
          : translate['male_young'];
        this.labels[2] = translate[`male_adult_${id}`]
          ? translate[`male_adult_${id}`]
          : translate['male_adult'];
        this.labels[3] = translate[`male_senior_${id}`]
          ? translate[`male_senior_${id}`]
          : translate['male_senior']; //translate.male_senior;
        this.labels[4] = translate[`female_child_${id}`]
          ? translate[`female_child_${id}`]
          : translate['female_child']; //translate.female_child;
        this.labels[5] = translate[`female_young_${id}`]
          ? translate[`female_young_${id}`]
          : translate['female_young']; //translate.female_young;
        this.labels[6] = translate[`female_adult_${id}`]
          ? translate[`female_adult_${id}`]
          : translate['female_adult']; //translate.female_adult;
        this.labels[7] = translate[`female_senior_${id}`]
          ? translate[`female_senior_${id}`]
          : translate['female_senior']; //translate.female_senior;

        this.colors[0] = translate[`male_child_color_${id}`]
          ? translate[`male_child_color_${id}`]
          : translate['male_child_color'];
        this.colors[1] = translate[`male_young_color_${id}`]
          ? translate[`male_young_color_${id}`]
          : translate['male_young_color'];
        this.colors[2] = translate[`male_adult_color_${id}`]
          ? translate[`male_adult_color_${id}`]
          : translate['male_adult_color'];
        this.colors[3] = translate[`male_senior_color_${id}`]
          ? translate[`male_senior_color_${id}`]
          : translate['male_senior_color']; //translate.male_senior;
        this.colors[4] = translate[`female_child_color_${id}`]
          ? translate[`female_child_color_${id}`]
          : translate['female_child_color']; //translate.female_child;
        this.colors[5] = translate[`female_young_color_${id}`]
          ? translate[`female_young_color_${id}`]
          : translate['female_young_color']; //translate.female_young;
        this.colors[6] = translate[`female_adult_color_${id}`]
          ? translate[`female_adult_color_${id}`]
          : translate['female_adult_color']; //translate.female_adult;
        this.colors[7] = translate[`female_senior_color_${id}`]
          ? translate[`female_senior_color_${id}`]
          : translate['female_senior_color']; //translate.female_senior;
      
      this.loading = false;
    }
  }
});
