import Vue from 'vue';
import { ClientManager } from '@/util/client';
import config from '@/config';
import store from '@/store';
import router from '@/router';
import axios from 'axios';
const clientManager = new ClientManager(config);
// console.log("config", config, clientManager)
const client = clientManager.testingClient;
// const clientFida = clientManager.ssoClient2;
const clientEn = clientManager.testingClient2;
const ssoClient = clientManager.sso;
const ssoClientFlowGo = clientManager.ssoClient;
//const clientFida = clientManager.fidaExportUrl;

const clientFida2 = clientManager.ssoClient2;

clientFida2.interceptors.request.use(
  config => {
    let token = '';
    if (store.getters['tenant/hasResetAccount']) {
      token = store.getters['user/getClientToken'];
    } else {
      token = store.getters['user/getToken'];
    }
    if (token) {
        config.headers = {
          Authorization: `bearer ${token}`,
          'Accept-Language': store.getters['user/getLocale']
            ? store.getters['user/getLocale']
            : 'en-us'
        };

    }
    return config;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      /* store.dispatch('user/clearSession');
      router.push({ name: 'auth:login' }).catch(_ => {});
      localStorage.clear(); */
    }
    return Promise.reject(error);
  }
);
ssoClientFlowGo.interceptors.request.use(
  config => {
    let token = '';
    if (store.getters['tenant/hasResetAccount']) {
      token = store.getters['user/getClientToken'];
    } else {
      token = store.getters['user/getToken'];
    }
    if (token) {
        config.headers = {
          Authorization: `bearer ${token}`,
          'Accept-Language': store.getters['user/getLocale']
            ? store.getters['user/getLocale']
            : 'en-us'
        };

    }
    return config;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      /* store.dispatch('user/clearSession');
      router.push({ name: 'auth:login' }).catch(_ => {});
      localStorage.clear(); */
    }
    return Promise.reject(error);
  }
);

clientEn.interceptors.request.use(
  config => {
    let token = '';
    if (store.getters['tenant/hasResetAccount']) {
      token = store.getters['user/getClientToken'];
    } else {
      token = store.getters['user/getToken'];
    }
    if (token) {
      // if(config.url == "kpi/target/sales")
      // {
      //   config.headers = {
      //     Authorization: `bearer ${token}`,
      //     'Accept-Language': store.getters['user/getLocale']
      //       ? 'en'
      //       : 'en'
      //   };
      // }
      // else
      // {
        config.headers = {
          Authorization: `bearer ${token}`,
          'Accept-Language': store.getters['user/getLocale']
            ? store.getters['user/getLocale']
            : 'en-us'
        };
      // }

    }
    return config;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      /* store.dispatch('user/clearSession');
      router.push({ name: 'auth:login' }).catch(_ => {});
      localStorage.clear(); */
    }
    return Promise.reject(error);
  }
);
client.interceptors.request.use(
  config => {
    let token = '';
    if (store.getters['tenant/hasResetAccount']) {
      token = store.getters['user/getClientToken'];
    } else {
      token = store.getters['user/getToken'];
    }
    if (token) {
      // if(config.url == "kpi/target/sales")
      // {
      //   config.headers = {
      //     Authorization: `bearer ${token}`,
      //     'Accept-Language': store.getters['user/getLocale']
      //       ? 'en'
      //       : 'en'
      //   };
      // }
      // else
      // {
        config.headers = {
          Authorization: `bearer ${token}`,
          'Accept-Language': store.getters['user/getLocale']
            ? store.getters['user/getLocale']
            : 'en-us'
        };
      // }

    }
    return config;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      /* store.dispatch('user/clearSession');
      router.push({ name: 'auth:login' }).catch(_ => {});
      localStorage.clear(); */
    }
    return Promise.reject(error);
  }
);
export function PutRequest(url, data = {}, config = {}) {
  return client.put(url, data, config);
}

export function PostRequestEN(url, data = {}, config = {}) {
  return clientEn.post(url, data, config);
}
export function PostRequest(url, data = {}, config = {}) {
  return client.post(url, data, config);

  // return fetch(`https://reporting-api.flow-insight.com/${url}`, {/${url}`, {
  //   method: 'POST', // *GET, POST, PUT, DELETE, etc.
  //   mode: 'cors', // no-cors, *cors, same-origin
  //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //   credentials: 'same-origin', // include, *same-origin, omit
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: 'Bearer 93bb3277-fa59-43ad-93ca-4f7b65520530'
  //     // 'Content-Type': 'application/x-www-form-urlencoded',
  //   },
  //   redirect: 'follow', // manual, *follow, error
  //   referrerPolicy: 'no-referrer', // no-referrer, *client
  //   body: JSON.stringify(data) // body data type must match "Content-Type" header
  // });
} // PostRequest

export function GetRequest(url, data, config: any = {}) {
  config.params = data;
  return Vue.axios.get(url, config);
} // GetRequest

export function Request(config: any = {}) {
  return Vue.axios.request(config);
} // Request

// export function PostRequestFidaExport(url, data = {}, config = {}) {
//   return clientFida.post(url, data, config);
// }

export function PostRequestFida(url, data , config: any = {}) {
    const postData = {
      ...data,
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
    }
  return clientFida2.post(url, postData, config);
}

export function GetRequestFidaExport(url, config: any = {}) {
  //const getTenentId = localStorage.getItem('tenentID');
  config.params = {
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET
  };
  return clientFida2.get(url, config);
}
export function GetRequestFidaExport2(url, data,config: any = {}) {
  //const getTenentId = localStorage.getItem('tenentID');
  config.params = {
    ...data,
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET
  };
  return clientFida2.get(url, config);
}

export async function DeleteRequestFida(url,id,config: any = {}) {

  const storeState: any = store.state;
  const token = storeState.user.sessionClient.access_token;
  const response = await axios.delete('https://fida.flow-insight.com/'+url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization:'Bearer '+ token
      },
      data: {
        ...id
      }
    });

  return response.data;//clientFida2.delete(url,config);
}

export async function  GetRequestFidaStores(url, config: any = {}) {
  const storeState: any = store.state;
  const token = storeState.user.sessionClient.access_token;
    config.params = {
      client_id: 'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688',
      client_secret: 'fp329-polk80s-ye04p1yy-45hx874z06',
      flag: "1"
    };
    config.headers = {
      'Content-Type': 'application/json',
      Authorization:'Bearer '+ token
    };
  return clientFida2.get(url, config);
}

export function PostRequestFidaExport(url, data = {}, config = {}) {
  return clientFida2.post(url, data, config);
}

export function PostRequestSSO(url, data = {}, config = {}) {
  return ssoClient.post(url, data, config);

} // PostRequest
//https://flowgo.flow-insight.com
export function GetRequestSSO(url, data = {}) {
  return ssoClientFlowGo.get(url, data);

}
export function PutRequestFidaExport(url, data = {}, config = {}) {
  return clientFida2.put(url, data, config);
}
