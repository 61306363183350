import {compatibleList, directCalcObj, formatValues, compListToFida} from '@/api/apiDataManagers/calc_consts';
import moment from 'moment';
const AvgTrend = ["cn", "ap", "upt", "rov", "aip", "sl", "sc", "se", "cr", "sh", "cs", "dw",
"spt", "tpt"];

function addValue(obj, itm, d, c) {
  if(!obj[d]) { obj[d] = {}; }
  if(!c[d]) { c[d] = {}; }  
  Object.keys(itm).forEach((k) => {
    let slSe = ((k == 'sl'  || k == 'cs' || k == 'se') && !itm.sh) ||
    ((k == 'tra' ) && !itm.st);
    let lK = k.toLowerCase()
    if(!obj[d][lK]) { obj[d][lK] = 0; }
    if(!c[d][lK]) { c[d][lK] = 0; }
    if(!isNaN(itm[k])) {
      if(k == "dw") {
        if(itm[k] != null) {
          obj[d][lK] += Number(itm[k])
          c[d][lK]++;
        }
      } if(k == "tra") {
        obj[d][lK] += slSe ? 0 : Number(itm[k])
        c[d][lK]++;
        if(itm.st) {
          obj[d]["tra_sls"] = (obj[d]["tra_sls"] || 0) + Number(itm["sls"])
          obj[d]["tra_st"] = (obj[d]["tra_st"] || 0) + Number(itm["st"])
        }
      } else {
        obj[d][lK] += slSe ? 0 : Number(itm[k])
        c[d][lK]++;
      }
    }
  })
}

function avgAll(obj, c, ar, userKPIs) {
  Object.keys(obj).forEach((d) => {
    userKPIs.forEach((k) => {

      k = compListToFida[k] || k;
      if(k == "sls") {
        obj[d]["targetrate"] = Number(obj[d][k]) || 0;
      }
      // if(k == "tra") {
      //   obj[d][k] = !obj[d].tra_sls || !obj[d].tra_st ? 0 : (obj[d].tra_sls * 100) / obj[d].tra_st;
      // } else 
      if(directCalcObj.isDirectCalc(k)) {
        obj[d][k] = directCalcObj.directCalcFn(k, obj[d], ar)
      } else if(AvgTrend.indexOf(k) > -1) {
        if(k == "dw") {
          obj[d]["dw_sum"] = (obj[d]["dw_sum"] || 0) + obj[d][k];
          obj[d]["dw_c"] = (obj[d]["dw_c"] || 0) + c[d][k];
        }
        obj[d][k] = Number(obj[d][k]/ c[d][k]);
      } else if(k != "_id") {
        obj[d][k] = Number(obj[d][k])
      }
      obj[d][k] = obj[d][k] || 0
    })
  })
}

function formatResponse(obj, metricKey) {
  let rV = []
  Object.keys(obj).forEach((e) => {
    let a = {timeLabel:e}
    Object.keys(obj[e]).forEach((l) => {
      a[l] = obj[e][l].toFixed(2)
    })
    a["value"] = obj[e][metricKey]
    a["formattedValue"] = formatValues(obj[e][metricKey], metricKey);
    rV.push(a)
  })
  return rV;
}

function resetKeys(obj) {
  obj.forEach((itm) => {
    Object.keys(compatibleList).forEach((k) => {
      if(itm[k] !== undefined) {
        itm[compatibleList[k]] = itm[k]
        delete itm[k]
      }
    })
  })
}

function getSumByKey(obj, k, d_k) {
  return obj.reduce((t, n) => {
    if(d_k) {
      return t + Number(n[d_k]) ? Number(n[k]) : 0;
    } else {
      return t + Number(n[k]);
    }
  }, 0);
}

function insertEmptyHourlyData(obj) {
  for(let i=1; i<obj.length; i++) {
    if(Number(obj[i].timeLabel) !== (Number(obj[i-1].timeLabel) + 1)) {
      let temp = JSON.parse(JSON.stringify(obj[i]));
      Object.keys(temp).forEach((kk) => {
        if(kk == "formattedValue") {
          temp[kk] = temp[kk].indexOf('¥') ? '¥0' : temp[kk].indexOf('%') ? '0%' : '0' 
        } else if(kk == "value") {
          temp[kk] = 0
        } else if(kk != "_id") {
          let c = temp[kk].split('.')
          c = c.length > 1 ? c[1].length: 0
          temp[kk] = (0).toFixed(c)
        }
      })
      temp.timeLabel = (Number(obj[i-1].timeLabel) + 1).toFixed(0)
      obj.splice(i, 0, temp)
    }
  }
}

function sortByTimeLabel(a, b)  {
  if(a.timeLabel < b.timeLabel) {
      return -1;
  } else if(a.timeLabel > b.timeLabel) {
      return 1;
  } else if(a.timeLabel < b.timeLabel) {
      return 0;
  }
}

function getZeroData(obj) {

  let retVal = {};
  Object.keys(obj).forEach((k) => {
    if(k == "value") {
      retVal.value = 0;
    } else if(k == "timeLabel") {
      retVal.timeLabel = obj.timeLabel;
    } else if(k == "timeLabel") {
      retVal.formattedValue = "0";
    } else {
      retVal.value = "0";
    }
  })
  return retVal;
}

function sumTrendKPI(kpis, ckpis, metricKey, freq, extData, stores) {
 
  const { area, cArea, userKPIs, startDate, compareStartDate } = extData;
  let sumObj = {}
  let sumObjC = {}
  let count = {}
  let countC = {}
  kpis.forEach((itm) => {
    addValue(sumObj, itm, itm._id[freq], count)
  })
  
  ckpis.forEach((itm) => {
    addValue(sumObjC, itm, itm._id[freq], countC)
  })
  
  avgAll(sumObj, count, area, userKPIs)
  avgAll(sumObjC, countC, cArea, userKPIs)
  let formatResp = formatResponse(sumObj, metricKey);
  let formatRespC = formatResponse(sumObjC, metricKey);
  
  resetKeys(formatResp);
  resetKeys(formatRespC);
  if(freq == "hour") {
    const t =  stores?.map(i => i?.storeTimings)?.flat();
    const timeings = t?.map(item => item.closingTime);
    // new code for add hour in :30 case
    const isHalf = timeings?.some(item => {
      const minutes = moment(item, "HH:mm").minutes();
      if(minutes > 0) {
        return true
      }
    });
    if(isHalf) {
      const lastLabelHour = formatResp[formatResp.length - 1];
      const overHour = Number(lastLabelHour?.timeLabel) + 1;
      const obj = {};
      Object.keys(lastLabelHour)?.forEach(i => {
        obj[i] = "0.0"
      })
      formatResp= [...formatResp, {...obj,timeLabel:overHour.toString(), value:0,formattedValue:"0", } ]
    
    } 
    // insertEmptyHourlyData(formatResp);
    // insertEmptyHourlyData(formatRespC);
    
    if(metricKey == "tra") {
      let slsSum = getSumByKey(formatResp, "sls")
      let tarSum = getSumByKey(formatResp, "st")
      let slsSumC = getSumByKey(formatRespC, "sls")
      let tarSumC = getSumByKey(formatRespC, "st")

      let t1 = tarSum < 1 ? 0 : ((slsSum * 100)/tarSum);
      if(isNaN(t1)) {
        t1 = 0;
      }
      formatResp = [{
        "timeLabel": startDate,
        "tra": t1.toFixed(2),
        "value": t1,
        "formattedValue": t1.toFixed(2) + " %"
      }]
      t1 = tarSumC < 1 ? 0 : ((slsSumC * 100)/tarSumC);
      if(isNaN(t1)) {
        t1 = 0;
      }
      formatRespC = [{
        "timeLabel": compareStartDate,
        "tra": t1.toFixed(2),
        "value": t1,
        "formattedValue": t1.toFixed(2) + " %"
      }]
    }

    if(metricKey == "dw") {
      let f_dw = kpis.filter((k) => k.dw);
      let dwSum = Math.floor(f_dw.reduce((s, a) => s+a.dw, 0)/(f_dw.length)).toFixed(0)
      let f_dwc = ckpis.filter((k) => k.dw);
      let dwSumC = Math.floor(f_dwc.reduce((s, a) => s+a.dw, 0)/(f_dwc.length)).toFixed(0)
      
      formatResp = [{
        "timeLabel": startDate,
        "dw": dwSum,
        "value": dwSum,
        "formattedValue": formatValues(dwSum, "dw")
      }]
      formatRespC = [{
        "timeLabel": compareStartDate,
        "dw": dwSumC,
        "value": dwSumC,
        "formattedValue": formatValues(dwSumC, "dw")
      }]
    }

  } 
  else {
    formatResp.sort(sortByTimeLabel)
    formatRespC.sort(sortByTimeLabel)


    let t1 = formatResp && formatResp[0] && formatResp[0].timeLabel
    let t2 = formatRespC && formatRespC[0] && formatRespC[0].timeLabel

    if(t2 > compareStartDate && formatRespC.length < formatResp.length) {
      
      let d = new Date(t2);

      while(formatRespC.length <= formatResp.length) {
        let day = d.getDay()
        if(day == 6 || day == 0) {
          d.setDate(d.getDate()+1)
        } else {
          let iD = getZeroData(formatRespC[0]);
          formatRespC.splice(0, 0, iD)
        }

      }
    }

  }
  let retVal = {description: "success", status: "SUCCESS",data: {selectedPeriod: formatResp, comparedPeriod: formatRespC}, compatibleKey: compatibleList[metricKey] || metricKey}
  return JSON.parse(JSON.stringify(retVal));
}

export default {
  sumTrendKPI
}