import { render, staticRenderFns } from "./UserConfigurationModal.vue?vue&type=template&id=8db79b9c&"
import script from "./UserConfigurationModal.vue?vue&type=script&lang=js&"
export * from "./UserConfigurationModal.vue?vue&type=script&lang=js&"
import style0 from "./UserConfigurationModal.vue?vue&type=style&index=0&id=8db79b9c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDialog,QCard,QForm,QCardSection,QItemLabel,QIcon,QTooltip,QInput,QSelect,QTable,QTh,QBtn,QSpace,QTd,QCheckbox,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QForm,QCardSection,QItemLabel,QIcon,QTooltip,QInput,QSelect,QTable,QTh,QBtn,QSpace,QTd,QCheckbox,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
