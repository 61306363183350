import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import management from './management';
import onboarding from './onboarding';
import settings from './settings';
import tools from './tools';
import marketplace from './marketplace';
import store from '../store/index';
import { APIFetchProfile } from '@/api/auth';
import { checkPermission, isAdAllow } from '@/api/utils';

Vue.use(VueRouter);
let routes = [
  {
    path: '/users',
    name: 'users',
    component: () => import('../pages/User.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/stores',
    name: 'stores',
    component: () => import('../pages/Store.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/store-details',
    name: 'storeDetails',
    component: () => import('../pages/storeDetails.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('../pages/Role.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('../pages/PeerGroupName.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/brands',
    name: 'brands',
    component: () => import('../pages/Operation.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/kpi',
    name: 'kpi',
    component: () => import('../pages/KPI.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/default',
    name: 'default',
    component: () => import('../pages/Default.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/general',
    name: 'general',
    component: () => import('../pages/Company.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/area-manager',
    name: 'area-manager',
    component: () => import('../pages/AreaManager.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/import',
    name: 'import',
    component: () => import('../pages/DataImport.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/Explore',
    name: 'Explore',
    component: () => import('../pages/Bots.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowAssistantExplorerView');
      if (viewAllowed) {
        next();
      } else {
        next('/detailed-report');
      }
    }
  },

  {
    //FlowEventReportView
    path: '/event/report',
    name: 'event_report',
    component: () => import('../pages/EventReport.vue'),
    meta: {
      // accessGiven: true, requiresAuth: false, layout: 'external'
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowEventReportView');
      if (viewAllowed) {
        next();
      } else {
        next('/detailed-report');
      }
    }
  },
  {
    path: '/event/management',
    name: 'event_management',
    component: () => import('../pages/EventManagement.vue'),
    meta: {
      // accessGiven: true, requiresAuth: false, layout: 'external'
      accessGiven: true,
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowEventReportView');
      if (viewAllowed) {
        next();
      } else {
        next('/detailed-report');
      }
    }
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/Home.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowDashboardView');
      if (viewAllowed) {
        next();
      } else {
        next('/detailed-report');
      }
    }
  },
  //  {
  //   path: '/delta',
  //   name: 'delta/home',
  //   component: () => import('../pages/Delta/Home.vue'),
  //   meta: {
  //     accessGiven: true
  //   },
  //   beforeEnter: (to, from, next) => {
  //     const viewAllowed = checkPermission('FlowDashboardView');
  //     if (viewAllowed) {
  //       next();
  //     } else {
  //       next('/detailed-report');
  //     }
  //   }
  // },
  // {
  //   path: '/templates',
  //   name: 'templates',
  //   component: () => import('../pages/Bots.vue'),
  //   meta: {
  //     accessGiven: true
  //   },
  //   beforeEnter: (to, from, next) => {
  //     // const viewAllowed = checkPermission('FlowAssistantTemplateView');
  //     // if (true) {
  //       next();
  //     // } else {
  //     //   next('/detailed-report');
  //     // }
  //   }
  // },
  {
    path: '/my-assistant',
    name: 'my-assistant',
    component: () => import('../pages/Bots.vue'),
    meta: { accessGiven: true },
    children: [
      {
        path: '/my-assistant/template-detail/:id',
        name: 'my-assistant:template-detail',
        component: () => import('../pages/Bots.vue'),
        meta: {
          accessGiven: true,
        }
      },
    ]
  },
  {
    path: '/admin-panel',
    name: 'admin-panel',
    component: () => import('../pages/Bots.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowAssistantMyAssistantView');
      if (viewAllowed) {
        next();
      } else {
        next('/detailed-report');
      }
    }
  },
  {
    path: '/NewSettings',
    name: 'NewSettings',
    component: () => import('../pages/NewSettings.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowAssistantEdit');
      if (viewAllowed) {
        next();
      } else {
        next('/detailed-report');
      }
    }
  },
  {
    path: '/lead-calculator',
    name: 'lead-calculator',
    component: () => import('../pages/LeadCalculator.vue'),
    meta: { accessGiven: true, requiresAuth: false, layout: 'external' }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/layouts/auth.vue'),
    meta: { accessGiven: true, requiresAuth: false, layout: 'external' },
    children: [
      {
        path: '/auth/login',
        name: 'auth:login',
        component: () => import('@/pages/Auth/index.vue'),
        meta: {
          accessGiven: true,
          requiresAuth: false,
          layout: 'external'
        }
      },
      {
        path: '/auth/signup',
        name: 'auth:signup',
        component: () => import('@/pages/Auth/index.vue'),
        meta: {
          accessGiven: true,
          requiresAuth: false,
          layout: 'external'
        }
      },
      {
        path: '/auth/signup/create',
        name: 'auth:signup:create',
        component: () => import('@/pages/Auth/Create.vue'),
        meta: {
          accessGiven: true,
          requiresAuth: false,
          layout: 'external'
        }
      },
      {
        path: '/auth/create/createAccount',
        name: 'auth:signup:createAccount',
        component: () => import('@/components/Auth/Create/CreateAccountScreen.vue'),
        meta: {
          accessGiven: true,
          requiresAuth: false,
          layout: 'external'
        }
      },

      {
        path: '/auth/signup/request',
        name: 'auth:signup:request',
        component: () => import('@/pages/Auth/Request.vue'),
        meta: {
          accessGiven: true,
          requiresAuth: false,
          layout: 'external'
        }
      },
      {
        path: '/auth/signup/limit',
        name: 'auth:signup:limit',
        component: () => import('@/pages/Auth/Limit.vue'),
        meta: {
          accessGiven: true,
          requiresAuth: false,
          layout: 'external'
        }
      },
      {
        path: '/auth/forgot',
        name: 'auth:forgot',
        component: () => import('@/pages/Auth/Forgot.vue'),
        meta: {
          accessGiven: true,
          requiresAuth: false,
          layout: 'external'
        }
      },
      {
        path: '/auth/reset/:email',
        name: 'auth:reset',
        component: () => import('@/pages/Auth/Reset.vue'),
        meta: {
          accessGiven: true,
          requiresAuth: false,
          layout: 'external'
        }
      }
    ]
  },
  {
    path: '/detailed-report',
    name: 'detailed_report',
    component: () => import('../pages/DetailedReport/DetailedReport.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowDetailReportView');
      if (viewAllowed) {
        next();
      } else {
        next('/traffic-report');
      }
    }
  },
  {
    path: '/traffic-report',
    name: 'traffic_report',
    component: () => import('../pages/TrafficReport.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowTrafficReportView');
      if (viewAllowed) {
        next();
      } else {
        next('/zone-report');
      }
    }
  },
  {
    path: '/zone-report',
    name: 'zone_report',
    component: () => import('../pages/ZoneReport.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowTrafficReportView');
      if (viewAllowed) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/occupancy-report',
    name: 'occupancy_report',
    component: () => import('../pages/Occupancy.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowOccupancyReportView');
      const adAllowed = isAdAllow();
      if (viewAllowed) {
        next();
      } else if (adAllowed) {
        next({ name: 'upgrade', params: { route: to.params.route } });
      }
    }
  },
  {
    path: '/partner-portal',
    name: 'partner_portal',
    component: () => import('../pages/PartnerPortal.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowDashboardView');
      if (viewAllowed) {
        next();
      } else {
        next('/queue-report');
      }
    }
  },
  {
    path: '/queue-report',
    name: 'queue_report',
    component: () => import('../pages/QueueReport.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowQueueReportView');
      const adAllowed = isAdAllow();
      if (viewAllowed) {
        next();
      } else if (adAllowed) {
        next({ name: 'upgrade', params: { route: to.params.route } });
      }
    }
  },
  {
    path: '/staff-performance',
    name: 'staff_performance',
    component: () => import('../pages/StaffPerformance.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.StaffPerformanceReport?.VIEW;
      if (viewAllowed) {
        next();
      } else {
        next('/location-report');
      }
    }
  },
  {
    path: '/location-report',
    name: 'location_report',
    component: () => import('../pages/LocationReport.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowLocationView');
      if (viewAllowed) {
        next();
      } else {
        next('/demographics-report');
      }
    }
  },
  {
    path: '/location-comparison-report',
    name: 'location_comparison_report',
    component: () => import('../pages/LocationComparisonReport.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowStoreComparionView');
      if (viewAllowed) {
        next();
      } else {
        next('/demographics-report');
      }
    }
  },
  {
    path: '/demographics-report',
    name: 'demographics-reportF',
    component: () => import('../pages/DemographicsReport.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowDemographicsView');
      const adAllowed = isAdAllow();
      if (viewAllowed) {
        next();
      } else if (adAllowed) {
        next({ name: 'upgrade', params: { route: to.params.route } });
      }
    }
  },
  {
    path: '/store-map',
    name: 'store-map',
    component: () => import('../pages/StoreMap.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowDemographicsView');
      if (viewAllowed) {
        next();
      } else {
        next('/export');
      }
    }
  },
  {
    path: '/space-analysis',
    name: 'space-analysis',
    component: () => import('../pages/SpaceAnalysis/SpaceAnalysis.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowPresenceView');
      if (viewAllowed) {
        next();
      } else {
        next('/export');
      }
    }
  },
  {
    path: '/export',
    name: 'export',
    component: () => import('../pages/Export.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowExportDownload');
      if (viewAllowed) {
        next();
      } else {
        next('/partners');
      }
    }
  },
  {
    path: '/trafficcalculator',
    name: 'trafficcalculator',
    component: () => import('../pages/TrafficCalculator.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowExportDownload');
      if (viewAllowed) {
        next();
      } else {
        next('/partners');
      }
    }
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import('../pages/Marketplace.vue'),
    meta: { accessGiven: true }, //
    beforeEnter: (to, from, next) => {
      next();
      // const viewAllowed =
      //   store.state['user']?.userPermissions?.MarketPlace?.VIEW;
      // if (viewAllowed) {
      //   next();
      // } else {
      //   next('*');
      // }
      // } else {
      //   localStorage.clear();
      //   next('/auth/login');
      // }
    }
  },
  {
    path: '/academy',
    name: 'academy',
    component: () => import('../pages/Academy.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowDashboardView');
      if (viewAllowed) {
        next();
      } else {
        next('/library');
      }
    }
  },
  {
    path: '/library',
    name: 'library',
    component: () => import('../pages/Library.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowDashboardView');
      if (viewAllowed) {
        next();
      } else {
        next('/user-management');
      }
    }
  },
  {
    path: '/user-management',
    name: 'user_management',
    component: () => import('../pages/UserManagement.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/alert-management');
      }
    }
  },
  {
    path: '/alert-management',
    name: 'alert_management',
    component: () => import('../pages/AlertManagement.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowDashboardView');
      if (viewAllowed) {
        next();
      } else {
        next('/user-group-management');
      }
    }
  },
  {
    path: '/user-group-management',
    name: 'user_group_management',
    component: () => import('../pages/UserGroupManagement.vue'),
    meta: { accessGiven: true },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowPeerGroupManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/layout-performance');
      }
    }
  },
  {
    path: '/periodic-report-list',
    name: 'periodic_report_list',
    component: () => import('../pages/PeriodicReportList.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowPulseLibraryView');
      const adAllowed = isAdAllow();
      if (viewAllowed) {
        next();
      } else if (adAllowed) {
        next({ name: 'upgrade', params: { route: to.params.route } });
      }
    }
  },
  {
    path: '/periodic-report-list-demo',
    name: 'periodic_report_list_demo',
    component: () => import('../pages/PeriodicReportListDemo.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowPulseLibraryView2');
      const adAllowed = isAdAllow();
      if (viewAllowed) {
        next();
      } else if (adAllowed) {
        next({ name: 'upgrade', params: { route: to.params.route } });
      }
    }
  },
  {
    path: '/periodic-report',
    name: 'periodic-report',
    component: () => import('../pages/PeriodicReport.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const pulseViewAllowed = checkPermission('FlowPulseLibraryView');
      const adAllowed = isAdAllow();
      if (pulseViewAllowed) {
        next();
      } else if (adAllowed) {
        next({ name: 'upgrade', params: { route: to.params.route } });
      }
    },
    children: [
      {
        path: '/daily-report',
        name: 'daily_report',
        component: () => import('../pages/PeriodicDailyReport.vue'),
        meta: {
          accessGiven: true,
          breadCrumb: 'Daily Report'
        },
        beforeEnter: (to, from, next) => {
          const dailyViewAllowed = checkPermission('FlowDailyReportView');
          const adAllowed = isAdAllow();
          if (dailyViewAllowed) {
            next();
          } else if (adAllowed) {
            next({ name: 'upgrade', params: { route: to.params.route } });
          }
        },
      },
      {
        path: '/daily-report/:reportId',
        name: 'daily_report',
        component: () => import('../pages/PeriodicDailyReport.vue'),
        meta: {
          accessGiven: true,
          breadCrumb: 'Daily Report'
        },
        
        beforeEnter: (to, from, next) => {
          const dailyViewAllowed = checkPermission('FlowDailyReportView');
          const adAllowed = isAdAllow();
          if (dailyViewAllowed) {
            next();
          } else if (adAllowed) {
            next({ name: 'upgrade', params: { route: to.params.route } });
          }
        },
      },
      {
        path: '/weekly-report',
        name: 'weekly_report',
        component: () => import('../pages/PeriodicWeeklyReport.vue'),
        meta: {
          accessGiven: true,
          breadCrumb: 'Weekly Report'
        },
        beforeEnter: (to, from, next) => {
          const weeklyViewAllowed = checkPermission('FlowWeeklyReportView');
          const adAllowed = isAdAllow();
          if (weeklyViewAllowed) {
            next();
          } else if (adAllowed) {
            next({ name: 'upgrade', params: { route: to.params.route } });
          }
        },
      },
      {
        path: '/weekly-report/:reportId',
        name: 'weekly_report',
        component: () => import('../pages/PeriodicWeeklyReport.vue'),
        meta: {
          accessGiven: true,
          breadCrumb: 'Weekly Report'
        },
        beforeEnter: (to, from, next) => {
          const weeklyViewAllowed = checkPermission('FlowWeeklyReportView');
          const adAllowed = isAdAllow();
          if (weeklyViewAllowed) {
            next();
          } else if (adAllowed) {
            next({ name: 'upgrade', params: { route: to.params.route } });
          }
        },
      },
      {
        path: '/monthly-report',
        name: 'monthly_report',
        component: () => import('../pages/PeriodicMonthlyReport.vue'),
        meta: {
          accessGiven: true,
          breadCrumb: 'Monthly Report'
        },
        beforeEnter: (to, from, next) => {
          // const monthlyViewAllowed = checkPermission('FlowMonthlyReportView');
          const monthlyViewAllowed = checkPermission('FlowMonthlyReportView');
          const adAllowed = isAdAllow();
          if (monthlyViewAllowed) {
            next();
           } else if (adAllowed) {
             next({ name: 'upgrade', params: { route: to.params.route } });
           }
        },
      },
      {
        path: '/monthly-report/:reportId',
        name: 'monthly_report',
        component: () => import('../pages/PeriodicMonthlyReport.vue'),
        meta: {
          accessGiven: true,
          breadCrumb: 'Monthly Report'
        },
        beforeEnter: (to, from, next) => {
          const monthlyViewAllowed = checkPermission('FlowMonthlyReportView');
          const adAllowed = isAdAllow();
          if (monthlyViewAllowed) {
            next();
          } else if (adAllowed) {
            next({ name: 'upgrade', params: { route: to.params.route } });
          }
        },
      }
    ]
  },
  {
    path: '/upgrade/:route',
    name: 'upgrade',
    component: () => import('../components/Common/UpgradeIframe.vue'),
    meta: { accessGiven: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../pages/Profile.vue'),
    meta: {
      accessGiven: true
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/pages/Account.vue'),
    meta: {
      accessGiven: true,
      requiresAuth: true
    }
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('@/pages/StubSignin.vue'),
    meta: { accessGiven: true, requiresAuth: false, layout: 'external' }
  },
  {
    path: '/api-status',
    name: 'api_status',
    component: () => import('@/pages/APIStatus.vue'),
    meta: { accessGiven: true, requiresAuth: false, layout: 'external' }
  },
  {
    path: '*',
    name: 'error',
    component: () => import('@/pages/Error.vue'),
    meta: { accessGiven: true, requiresAuth: false, layout: 'external' }
  }
];

routes = [
  ...marketplace,
  ...routes,
  ...management,
  ...tools,
  ...onboarding,
  ...settings,
  {
    path: '/:marketType/:detail',
    name: 'integration_details',
    component: () => import('../pages/IntegrationDetails.vue'),
    meta: { accessGiven: true }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
}); // new VueRouter

router.beforeEach(async (to, from, next) => {
  if(to?.query?.error && to?.query?.error == "401") {
    
    Vue.prototype.$q.notify({
      type: 'negative',
      position: 'top',
      message: store.state['user'].translate.something_went_wrong
    });
    setTimeout(() => {
      history.replaceState({}, document.title, "/");
    }, 5000);
    next()
  } else if(to?.query?.auth) {
    console.log("found Auth", to.query.auth)

    await store.dispatch('user/loginUser', { accessToken: to.query.auth });

    await store.dispatch('comparison/fetchCompareDates');
    await store.dispatch('comparison/fetchMarketingCampaign');
    localStorage.setItem('islogin_sso', '1');
    setTimeout(() => {
      history.replaceState({}, document.title, "/");
    }, 5000);
    next()
  } else if (to.query && to.query.token) {
    const tempSession = {
      expired: false,
      expiration: to.query.expiration,
      expiresIn: to.query.expiresIn,
      tokenType: 'bearer',
      value: to.query.token
    };
    store.commit('user/setSession', { session: tempSession });
    store.commit('user/setBearerToken', { token: to.query.token });
    try {
      APIFetchProfile({}).then(res => {
        store.commit('user/setProfile', { profile: res.data });
        store.commit('filter/setStoreCodes', {
          storeCodes: res.data.storeCodes.split(',')
        });
      });
    } catch (error) {
      this.$q.notify({
        type: 'negative',
        position: 'top',
        message: error
      });
    }
    next('/');
  } else {
    if (to.meta.accessGiven) {
      next();
    } else {
      next('/');
    }
  }
});

export default router;
