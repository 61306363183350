import { APIFetchOverview, APIFetchDetailOverview } from '@/api/overview';
import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';
import {
  convertApiResponseDistribution
} from '@/api/fida';
import { getKpiNameByKey } from '@/store/modules/exportFida';
import DashboardManager from '@/api/apiDataManagers/dashboardManager.js';

import {resetComparisionByLocation} from '@/api/apiDataManagers/fidaToOldConvertor';

const initialState = {
  kpis: {},
  compareData: {},
  regions: [],
  districts: [],
  stores: [],
  groups: [],
  SVs: [],
  allTrends: {},
  byPeriod: {
    selectedKPIValues: [],
    comparedKPIValues: []
  }, // byPeriod
  callTracker: {},
  storeTimings:[],
};

export default {
  state: initialState,
  mutations: {
    setDistributionsByPeriod(state, { byPeriod }) {
      state.byPeriod = byPeriod;
    },
    setKpis(state, { kpis }) {
      state.kpis = kpis;
    },
    setTracker(state, { callerId, value }) {
      state.callTracker[callerId] = value;
    },
    setComparedData(state, { compareData }) {
      state.compareData = compareData;
    },
    setRegions(state, { regions }) {
      state.regions = regions;
    }, // setRegions
    setDistricts(state, { districts }) {
      state.districts = districts;
    }, // setDistricts
    setStores(state, { stores }) {
      state.stores = stores;
    },
    setGroups(state, { groups }) {
      state.groups = groups;
    }, // setGroups
    setSVs(state, { SVs }) {
      state.SVs = SVs;
    }, // setSVs
    setAllTrends(state, { allTrends }) {
      state.allTrends = allTrends;
    },
    setAllStoreTimeings(state, payload) {
      state.storeTimings = payload;
    },
  },
  actions: {
    setDataByLocation({ state, commit }) {
      commit('setRegions', { regions: state.compareData.regions });
      commit('setDistricts', { districts: state.compareData.districts });
      commit('setStores', { stores: state.compareData.stores });
      commit('setGroups', { groups: state.compareData.peerGroups });
      commit('setSVs', { SVs: state.compareData.areaManagers });
    },
    setDataByLocationFormatKey({state, commit, dispatch}, {key}) {
      if(Object.keys(state?.compareData || {}).length) {
        let thisData = JSON.parse(JSON.stringify(state.compareData))
        Object.values(thisData).forEach((itm) => {
          resetComparisionByLocation(itm, key)
        })
        commit('setComparedData', { compareData: thisData });
        dispatch("setDataByLocation")
      }

    },
    async fetchOverview(
      { commit, dispatch, state },
      {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        kpis,
        groupByMetricKey,
        comparisonYKPIMetricKey,
        trackLatest,
        status
      }
    ) {
      let res = {} as any;
      if(trackLatest) {
        commit('setTracker', trackLatest);
      }
      if(storeCodes != 
        "")
        {
      res = await APIFetchOverview({
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        storeCodes,
        daysOfWeek,
        kpis: kpis ? kpis : 'all',
        groupByMetricKey,
        comparisonYKPIMetricKey,
        status
      });
      if(trackLatest) {
        if(state.callTracker[trackLatest.callerId] !== trackLatest.value) {
          return false;
        }
      }
     
      if(status && status == 'kpitree')
      {
 
        return res;
      }
      else
      {
        commit('setKpis', {
          kpis: res.data && res.data.length ? res.data[0].kpis : {}
    
        });


        commit('setComparedData', { compareData: res.compareData });
        dispatch("setDataByLocation")
        commit('setAllTrends', { allTrends: res.allTrends });
        commit('setAllStoreTimeings', res.stores);
        return res;
      }
      
    }
    else
      return [];
    },
    async fetchDetailOverview(
      { commit },
      {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        kpis,
        groupByMetricKey
      }
    ) {

        if(kpis == "")
        return;
        let res = await DashboardManager.fetchOverviewforDetail({

          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          "storeCodes": storeCodes,
          "kpiTypes": kpis,
          "frequency": "daily",
          daysOfWeek
        })
        res.startDate = startDate;
        res.endDate = endDate;
        res.compareStartDate = compareStartDate;
        res.compareEndDate = compareEndDate;

        ////////
         const distributionResult = convertApiResponseDistribution(res.data[0].kpis);
       
        if (
          distributionResult.data?.selectedKPIValues.length !== 0 &&
          distributionResult.data?.comparedKPIValues.length !== 0
        ) {
          commit('setDistributionsByPeriod', { byPeriod: distributionResult.data });
        }

        res.data[0].kpis = sortByKPISequence(kpis,res.data[0].kpis);

        commit('setKpis', {
          kpis: res.data && res.data.length ? res.data[0].kpis : {}
        });
        return res;
    }
  }
};



const sortByKPISequence = (kpiSequence, object) => {
  const sequenceArray = kpiSequence.split(',');

  // Sort keys based on the order in kpiSequence
  const sortedKeys = Object.keys(object).sort((a, b) => {
    const orderA = sequenceArray.indexOf(a);
    const orderB = sequenceArray.indexOf(b);
    return orderA - orderB;
  });

  // Create a new object with sorted keys
  const sortedObject = {};
  sortedKeys.forEach(key => {
    sortedObject[key] = object[key];
  });

  return sortedObject;
};


interface ConvertedResponse {
  data: {
      startDate: string;
      endDate: string;
      compareStartDate: string;
      compareEndDate: string;
      kpis: Record<string, {
          metricKey: string;
          metricKeyShort: string;
          value: string;
          comparedValue: string;
          variation: string;
          variationPercentage: string;
          growthIndicator: number;
          variance: string;
          metricID: string;
      }>;
  }[];
}

function convertApiResponse(apiResponse: any): ConvertedResponse {
  const convertedData: ConvertedResponse = {
      data: []
  };

  const kpis: Record<string, any> = {};

  const sumByKey = {};
  const sumByKeyComp = {};

  apiResponse.kpis.forEach(item => {
    Object.keys(item).forEach(key => {
        if (key !== "_id") {
            sumByKey[key] = (sumByKey[key] || 0) + (Number(item[key]) || 0);
        }
    });
});

apiResponse.kpisCompare.forEach(item => {
  Object.keys(item).forEach(key => {
      if (key !== "_id") {
        sumByKeyComp[key] = (sumByKeyComp[key] || 0) + (Number(item[key]) || 0);
      }
  });
});

  for (const key in sumByKey) {

      const kpi = sumByKey[key]?sumByKey[key]:0;
      const compareKpi = sumByKeyComp[key]?sumByKeyComp[key]:0;
      const compareTotalCount = compareKpi === 0 ? 1 : compareKpi;
      const variationPercentage = ((kpi / compareTotalCount) * 100).toFixed(2);
      const value = `${getSignsLeftSide(key)}${kpi.toLocaleString()}${getSignsRightSide(key)}`;
      const comparedValue = `${getSignsLeftSide(key)}${compareKpi.toLocaleString()}${getSignsRightSide(key)}`;
      var growthIndicator=1;
      if(kpi<compareKpi)
      growthIndicator = -1;

      kpis[key] = {
          metricKey: getKpiNameByKey(key),
          metricKeyShort: getKpiNameByKey(key),
          value: value,
          comparedValue: comparedValue,
          variation: (kpi - compareKpi).toLocaleString(),
          variationPercentage: `${variationPercentage}%`,
          growthIndicator: growthIndicator,
          variance: `${variationPercentage}%`,
          metricID: key
      };
  }

  convertedData.data.push({
      startDate: apiResponse.startDate,
      endDate: apiResponse.endDate,
      compareStartDate: apiResponse.compareStartDate,
      compareEndDate: apiResponse.compareEndDate,
      kpis
  });

  return convertedData;
}