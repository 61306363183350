
import stores from '@/services/stores';
import moment from 'moment';
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['visible'],
  data() {
    return {
      internalVisible: this.visible,
      hoursData: [],
      loading: false
    };
  },

  watch: {
    visible(newValue) {
      this.internalVisible = newValue;
    },
    internalVisible(newValue) {
      if (!newValue) {
        this.$emit('close');
      } else {
        this.getStoreLogs();
      }
    }
  },
  // mounted(){
  //   this.getStoreLogs();
  // },
  methods: {
    addHour(time){
      const minutes = moment(time, "HH:mm").minutes();
      if(minutes > 0){
        return moment(time, 'HH:mm').format('HH:mm')
      }else if(minutes == 0){
        return moment(time, 'HH:mm').add(59,'minutes').format('HH:mm')
      }
    },
    foramtedTime(input) {
      return moment(input, 'HH:mm:ss').format('HH:mm');
    },
    regularHours(storeTimings) {
      const days = [
        { label: this.$store.state.user.translate.sunday, value: 'sunday' },
        { label: this.$store.state.user.translate.monday, value: 'monday' },
        { label: this.$store.state.user.translate.tuesday, value: 'tuesday' },
        {
          label: this.$store.state.user.translate.wednesday,
          value: 'wednesday'
        },
        { label: this.$store.state.user.translate.thursday, value: 'thursday' },
        { label: this.$store.state.user.translate.friday, value: 'friday' },
        { label: this.$store.state.user.translate.saturday, value: 'saturday' }
      ];

      return days.map(day => {
        const dayTiming = storeTimings.find(item => item.day === day.value);
        const d = dayTiming ? { ...dayTiming, day: day.label } : null;
        return d || { day: day.label };
      });
    },
    getStartEndDates(data) {
      const allDates = data?.flatMap(item => item.dates);
      // Sort dates in ascending order
      allDates.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
      // Get start and end date
      const startDate = moment(allDates[0]).format('YYYY/MM/DD');
      const endDate = moment(allDates[allDates.length - 1]).format(
        'YYYY/MM/DD'
      );
      return `${startDate} ~ ${endDate}`;
    },
    getFDate(inputDate) {
      return moment(inputDate).format('YYYY/MM/DD (ddd)');
    },
    getDate(inputDate) {
    const lang = this.$store.state.locale || 'en'
    const formatEn = 'YYYY/MM/DD, HH:mm';
    const formatJa = 'YYYY年MM月DD日 HH時mm分';
    
    return moment.utc(inputDate).local().format(lang === 'ja' ? formatJa : formatEn);
},
    // getDate(inputDate) {
    //   const formattedDate = moment(inputDate).format('YYYY/MM/DD, HH:mm');
    //   return formattedDate;
    // },
    async getStoreLogs() {
      try {
        this.loading = true;
        const { id } = this.$route.query;
        const res = await stores.getStoreLogs(id);
        if (res?.data?.response?.data) {
          this.hoursData = res?.data?.response?.data?.reverse();
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    closeModal() {
      this.internalVisible = false;
    }
  }
};
