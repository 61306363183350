var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-card',{staticClass:"fit",attrs:{"flat":""}},[_c('span',{staticStyle:{"display":"flex"}},[_c('BoxHeader',{attrs:{"title":_vm.$store.state.user.translate.age_groups || 'Age Groups'}})],1),_c('div',{staticClass:"value-percentage-wrapper"},[_c('q-btn-toggle',{staticClass:"value-percentage",attrs:{"flat":"","options":[
        {
          label: this.$store.state.user.translate.value || 'Value',
          value: 'value'
        },
        { label: '%', value: 'percentage' }
      ]},model:{value:(_vm.periodType),callback:function ($$v) {_vm.periodType=$$v},expression:"periodType"}})],1),_c('q-card-section',{staticClass:"row"},[_c('div',{staticClass:"col-12 row justify-center align-center zone-chartpie"},[(_vm.periodType == 'percentage')?_c('ChartPie',{attrs:{"series":_vm.seriesPer,"labels":_vm.labels,"colors":_vm.colors,"height":250}}):_c('ChartPieValue',{attrs:{"series":_vm.series,"labels":_vm.labels,"colors":_vm.colors}})],1),_c('LoadingSpinner',{attrs:{"loading":_vm.loading}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }