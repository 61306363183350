export const reportMenu = {
  // 'Reports -> DashboardV2': {
  //   label: 'dashboardv2',
  //   route: 'dashboard',
  //   code: 'FlowDashboardView'
  // },
  'Reports -> Dashboard': {
    label: 'dashboard',
    route: 'home',
    code: 'FlowDashboardView'
  },
  'Reports -> Detailed Report': {
    label: 'detailed_report',
    route: 'detailed_report',
    code: 'FlowDetailReportView'
  },
  'Reports -> Traffic Report': {
    label: 'traffic_report',
    route: 'traffic_report',
    code: 'FlowTrafficReportView'
  },
  'Reports -> Zone Report': {
    label: 'zone_report',
    route: 'zone_report',
    code: 'FlowZoneReportView'
  },
  'Reports -> Location Report': {
    label: 'location_report',
    route: 'location_report',
    code: 'FlowLocationView'
  },
  'Reports -> Demographics Report': {
    label: 'demographics_report',
    route: 'demographics-reportF',
    code: 'FlowDemographicsView'
  },
  'Reports -> Location Comparison Report': {
    label: 'location_comparison_report',
    route: 'location_comparison_report',
    code: 'FlowStoreComparionView'
  },
  'Reports -> Queue Notifier Report': {
    label: 'queue_report',
    route: 'queue_report',
    code: 'FlowQueueReportView'
  }
};

export const VMDMenu = {
  'Reports -> Occupancy Report': {
    label: 'occupancy_report',
    route: 'occupancy_report',
    code: 'FlowOccupancyReportView'
  },
  'Reports -> Space Analysis': {
    label: 'space_analysis',
    route: 'space-analysis',
    code: 'FlowPresenceView'
  }
};

export const pulseMenu = {
  'Pulse -> Daily': {
    label: 'dailyReport',
    route: 'daily_report',
    code: 'FlowDailyReportView'
  },
  'Pulse -> Weekly': {
    label: 'weeklyReport',
    route: 'weekly_report',
    code: 'FlowWeeklyReportView'
  },
  'Pulse -> Monthly': {
    label: 'monthlyReport',
    route: 'monthly_report',
    code: 'FlowMonthlyReportView'
  },
  'Pulse -> Library': {
    label: 'library',
    route: 'periodic_report_list',
    code: 'FlowPulseLibraryView'
  },
};

export const academyMenu = {
  'Academy -> Academy': {
    label: 'Academy',
    route: 'academy',
    code: 'FlowDashboardView'
  },
  'Academy -> Library': {
    label: 'Library',
    route: 'library',
    code: 'FlowDashboardView'
  }
};

export const settingMenu = {
  // 'Settings -> Users Management': {
  //   label: 'user_management',
  //   route: 'user_management',
  //   code: 'users'
  // },
  // 'Settings -> Alert Settings': {
  //   label: 'alert_manager',
  //   route: 'alert_management',
  //   code: 'FlowDashboardView'
  // },
  // 'Settings -> User Groups Management': {
  // label: 'user_group_management',
  // route: 'user_group_management',
  // code: 'FlowPeerGroupManagementView'
  // }
};

export const toolsMenu = {
  'Date Import -> Export': {
    label: 'export_data',
    route: 'export',
    code: 'FlowExportDownload'
  },
  // 'Tools -> Event Report': {
  //   label: 'event_report',
  //   route: 'event_report',
  //   code: 'FlowOccupancyReportView'
  // },
  // 'Tools -> Event Management': {
  //   label: 'event_management',
  //   route: 'event_management',
  //   code: 'FlowOccupancyReportView'
  // },
};

export const SETTING_MENU_ITEMS = [
  'FlowStoreManagementView',
  'FlowUserManagementView',
  'FlowRoleMangementView',
  'FlowBrandManagementView',
  'FlowMarketplaceView',
  'FlowDefaultSettingsView',
  'headquartersSelectPeriods',
  'FlowPeerGroupManagementView',
  'FlowAreaManagerManagementView'
];
