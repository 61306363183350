
import { mapState, mapGetters } from 'vuex';
import * as XLSX from 'xlsx';
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['zone'],
  data() {
    return {
      activeTab: 1,
      tabs: [{ label: this.$store.state.user.translate.value }, { label: '%' }],
      columnWidths: [80, 150, 200],
      isResizingColumn: null,
      startX: null,
      startWidth: null,
      columns: [
        {
          name: 'name',
          label: this.$store.state.user.translate.name,
          field: 'name',
          align: 'left',
          sortable: true,
          visible: true
        },
        {
          name: 'male_18',
          label: this.$store.state.user.translate.male_child,
          field: 'male_18',
          align: 'center',
          sortable: true,
          visible: true
        },

        {
          name: 'male_19_44',
          label: this.$store.state.user.translate.male_young,
          field: 'male_19_44',
          align: 'center',
          sortable: true,
          visible: true
        },
        {
          name: 'male_45_59',
          label: this.$store.state.user.translate.male_adult,
          field: 'male_45_59',
          align: 'center',
          sortable: true,
          visible: true
        },
        {
          name: 'male_60',
          label: this.$store.state.user.translate.male_senior,
          field: 'male_60',
          align: 'center',
          sortable: true,
          visible: true
        },
        {
          name: 'female_18',
          label: this.$store.state.user.translate.female_child,
          field: 'female_18',
          align: 'center',
          sortable: true,
          visible: true
        },
        {
          name: 'female_19_44',
          label: this.$store.state.user.translate.female_young,
          field: 'female_19_44',
          align: 'center',
          sortable: true,
          visible: true
        },
        {
          name: 'female_45_59',
          label: this.$store.state.user.translate.female_adult,
          field: 'female_45_59',
          align: 'center',
          sortable: true,
          visible: true
        },
        {
          name: 'female_60',
          label: this.$store.state.user.translate.female_senior,
          field: 'female_60',
          align: 'center',
          sortable: true,
          visible: true
        }
      ],
      rows: [],
      rowsB: []
    };
  },
  computed: {
    ...mapState('store', ['stores']),
    ...mapGetters('filter', [
      'getStartingPeriod',
      'getEndingPeriod',
      'getStoreCodesList'
    ]),
    visibleColumns() {
      return this.columns.filter(col => col.visible);
    },
    formattedRows() {
      return this.rows.map(row => {
        const formattedRow = { ...row };
        if (this.activeTab === 0) {
          return formattedRow;
        }
        Object.keys(formattedRow).forEach(key => {
          if (typeof formattedRow[key] === 'number') {
            formattedRow[key] = 1;
          }
        });
        return formattedRow;
      });
    }
  },
  watch: {
    zone(newValue) {
      this.addZoneMethod();
    }
  },
  methods: {
    methodChange(index) {
      this.activeTab = index;
    },
    addAndRemovePercentageSymbols(data, add = this.activeTab) {
      return data?.map(zone => {
        const sumValues = obj =>
          Object.values(obj)
            .filter(value => typeof value === 'number')
            .reduce((acc, num) => acc + num, 0);

        const p = sumValues(zone) || 1; // Avoid division by zero

        return {
          ...zone,
          ...Object.fromEntries(
            Object.entries(zone).map(([key, value]) => {
              if (typeof value === 'number')
                return [
                  key,
                  add ? `${((value / p) * 100).toFixed(2)}%` : value
                ];
              return [key, value];
            })
          ),
          subZones:
            zone.subZones?.map(subZone => {
              const sp = sumValues(subZone) || 1; // Avoid division by zero
              return {
                ...subZone,
                ...Object.fromEntries(
                  Object.entries(subZone).map(([key, value]) => {
                    if (typeof value === 'number')
                      return [
                        key,
                        add ? `${((value / sp) * 100).toFixed(2)}%` : value
                      ];
                    return [key, value];
                  })
                )
              };
            }) || []
        };
      });
    },
    addZoneMethod() {
      const { stores } = this.stores;
      const rows = Object.keys(this.zone).map(key => {
        const { summary, zones } = this.zone[key];
        const findName = stores?.find(name => name.storeCode == key)?.name;
        return {
          name: findName ? `${key} - ${findName}` : key,
          male_18: summary.dm[1],
          male_19_44: summary.dm[2],
          male_45_59: summary.dm[3],
          male_60: summary.dm[4],
          female_18: summary.dm[5],
          female_19_44: summary.dm[6],
          female_45_59: summary.dm[7],
          female_60: summary.dm[8],
          expand: false,
          subZones: Object.keys(zones)?.map(k => {
            const zon = zones[k];
            return {
              name: findName ? `${findName} ${k}` : k,
              male_18: zon.dm[1],
              male_19_44: zon.dm[2],
              male_45_59: zon.dm[3],
              male_60: zon.dm[4],
              female_18: zon.dm[5],
              female_19_44: zon.dm[6],
              female_45_59: zon.dm[7],
              female_60: zon.dm[8]
            };
          })
        };
      });
      this.rows = rows;
      this.rowsB = this.addAndRemovePercentageSymbols(rows);
    },

    toggleExpand(row) {
      row.expand = !row.expand;
      this.rows = [...this.rows];
    },
    exportToCSV() {
  const rows = this.activeTab ? this.rowsB : this.rows;
  let dataToExport = [];

  rows.forEach(row => {
    let rowData = {};
    this.visibleColumns.forEach(col => {
      if (row.hasOwnProperty(col.name)) {
        rowData[col.label] = row[col.name] || "-";
      }
    });
    dataToExport.push(rowData);

    // Include subzones only with visible columns
    if (row.subZones?.length) {
      row.subZones.forEach(subZone => {
        let subZoneData = {};
        this.visibleColumns.forEach(col => {
          if (subZone.hasOwnProperty(col.name)) {
            subZoneData[col.label] = subZone[col.name] || "-";
          }
        });
        dataToExport.push(subZoneData);
      });
    }
  });

  // Generate CSV content based on visible columns
  const csvContent = [
    this.visibleColumns.map(col => col.label).join(","), // Headers
    ...dataToExport.map(row => Object.values(row).map(value => `"${value}"`).join(",")) // Rows
  ].join("\n");

  // Create and trigger CSV download
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${this.$store.state.user.translate.gender_age_overview}_${this.getStartingPeriod}_${this.getEndingPeriod}.csv`;
  document.body.appendChild(link);
  link.click();
},

exportToExcel() {
  const rows = this.activeTab ? this.rowsB : this.rows;
  let dataToExport = [];

  rows.forEach(row => {
    let rowData = {};
    this.visibleColumns.forEach(col => {
      if (row.hasOwnProperty(col.name)) {
        rowData[col.label] = row[col.name] || "-";
      }
    });
    dataToExport.push(rowData);

    // Include subzones only with visible columns
    if (row.subZones?.length) {
      row.subZones.forEach(subZone => {
        let subZoneData = {};
        this.visibleColumns.forEach(col => {
          if (subZone.hasOwnProperty(col.name)) {
            subZoneData[col.label] = subZone[col.name] || "-";
          }
        });
        dataToExport.push(subZoneData);
      });
    }
  });

  // Convert data to Excel format
  const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Zone Data");

  // Trigger Excel file download
  XLSX.writeFile(workbook, `${this.$store.state.user.translate.gender_age_overview}_${this.getStartingPeriod}_${this.getEndingPeriod}.xlsx`);
}


  }
};
