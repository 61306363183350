
export default {
  // eslint-disable-next-line vue/require-prop-types
  props:["dataList"],
  data() {
    return {
      funnelData: [
        { label: 'Passersby', percentage: 0, value: 0 },
        { label: 'Traffic', percentage: 100, value: 1000 },
        { label: 'Zone Passers...', percentage: 0, value: 0 },
        { label: 'Stops', percentage: 30, value: 300 },
        { label: 'Transactions', percentage: 10, value: 100 }
      ]
    };
  }
};
