var render, staticRenderFns
import script from "./ChartPieValue.vue?vue&type=script&lang=ts&"
export * from "./ChartPieValue.vue?vue&type=script&lang=ts&"
import style0 from "./ChartPieValue.vue?vue&type=style&index=0&id=989dc478&prod&scoped=true&lang=scss&"
import style1 from "./ChartPieValue.vue?vue&type=style&index=1&id=989dc478&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "989dc478",
  null
  
)

export default component.exports
import {QCard} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard})
