
import moment from 'moment';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    apexchart: VueApexCharts
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['dwellData'],

  data() {
    return {
      selectedZone: '',
      zones: [
        // { key: '01', value: 'Zone 01' },
      ],
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: '400px',
          toolbar: {
            tools: {
              download:
                '<div className="set-icon"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 32"><circle cx="12" cy="4" r="4" fill="black"/><circle cx="12" cy="15" r="4" fill="black"/><circle cx="12" cy="26" r="4" fill="black"/></svg></div>'
            },
            export: {
              svg: {
                filename: `${this.$store.state.user.translate.dwell_time_distribution}_${this.$store.state.filter.filter.startingPeriod}_${this.$store.state.filter.filter.endingPeriod}`
              },
              png: {
                filename: `${this.$store.state.user.translate.dwell_time_distribution}_${this.$store.state.filter.filter.startingPeriod}_${this.$store.state.filter.filter.endingPeriod}`
              }
            }
          }
        },
        title: {
          text: this.$store.state.user.translate.dwell_time_distribution,
          align: 'left'
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        tooltip: {
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            const translations = this.$store.state.user.translate;
            return `<div class="custom-tooltip">
                      <div class="toolTitle">
                        ${translations.dwell_time}: ${w.globals.labels[dataPointIndex]} 
                        ${translations.mins || "mins"}
                      </div>
                      <div class="toolcontent">
                        <span class="blue-dot"></span>${translations.no_of_people || "No. of People"}: 
                        <strong>${series[seriesIndex][dataPointIndex]}</strong>
                      </div>
                    </div>`;
          }.bind(this)
        },

        dataLabels: {
          enabled: false,
          formatter: (val, { dataPointIndex }) => val + 'y'
        },
        colors: ['#2196F3']
      }
    };
  },
  computed: {
  translations() {
    return this.$store.state.user.translate;
  }
},
  watch: {
    dwellData: {
      handler: function(data) {
        this.zones = Object.keys(data).map(item => {
          return { key: item, value: `${item}` };
        });
        this.selectedZone = this.zones[0]?.key;
      },
      deep: true
    },
    selectedZone: {
      handler: function(key) {
        this.addDwelMethod(this.dwellData[key]);
      }
    }
  },
  // mounted() {
  //   this.addDwelMethod();
  // },
  methods: {
    addDwelMethod(data) {
      // const { selected } = this.dwellData;
      if (!data || !Array.isArray(data)) return;
      const labels = data?.map(i => i.key);
      const numericData = data?.map(i => i.value);
      this.series = [
        {
          name: this.$store.state.user.translate?.dwell_time,
          data: numericData
        }
      ];
      let lang = this.$store.state.user.locale;
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: labels
        }
      };
      // replace button
      setTimeout(() => {
        const toolbar = document.querySelector('.exportCSV');
        if (toolbar) {
          const newDiv = document.createElement('div');
          newDiv.textContent = this.$store.state.user.translate.download_csv;
          newDiv.classList.add('apexcharts-menu-item');
          newDiv.classList.add('exportCSV');
          toolbar.replaceWith(newDiv);
          newDiv.addEventListener('click', () => {
            this.downloadCSV();
          });
        }
      }, 1000);
    },
    downloadCSV() {
      let csvContent = 'data:text/csv;charset=utf-8,';
      csvContent += 'label,Dwell Time\n'; // CSV Headers
      // const { selected } = this.dwellData?.zones[this.selectedZone];
      this.dwellData[this.selectedZone]?.forEach((item, index) => {
        let timeLabel = this.chartOptions.xaxis.categories[index];
        let dwellTime = item.value; // Ensure string values
        csvContent += `"${timeLabel}","${dwellTime}"\n`;
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute(
        'download',
        `${this.$store.state.user.translate.dwell_time_distribution}_${this.$store.state.filter.filter.startingPeriod}_${this.$store.state.filter.filter.endingPeriod}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
