import { APIGetReportingSumByLocationDataFidaV2} from '@/api/fidaApi.ts'
import convertor from '@/api/apiDataManagers/fidaToOldConvertor';
import trendCalc from '@/api/apiDataManagers/trend_calc';
import { APIFetchStoresFidaApi } from '@/api/store';
import { getStoreCodesByLocations } from '@/util/utilFunctions';
import { getStoreGroupsByStoreCodeList } from '@/store/modules/exportFida';
import fidaToOldConvertor from './fidaToOldConvertor';
import {getDistrictStoreCodes, getAllRelatedKpis} from '@/api/apiDataManagers/calc_consts';
import {fillEmptyData} from "./empty_data"

let storeData = null;
let storeNamesbyCode = {}

async function getStoreData() {
  let hasData = localStorage.getItem('dashboardManager.hasStoreData')
  if (!hasData) {
    storeData = (await APIFetchStoresFidaApi()).data.response;
    Object.values(storeData).forEach((gS) => {
      gS.forEach((s) => {
        storeNamesbyCode[s.storeNumber] = s.title
      })
    })
  }
  return storeData
}

async function fetchDataForQA(params) {

  let newparam = JSON.parse(JSON.stringify(params))
  delete newparam.compareEndDate
  delete newparam.compareStartDate
  let salesResp = {}
  salesResp = await APIGetReportingSumByLocationDataFidaV2({
    ...newparam, ...{
      kpiTypes: ['trf', 'sls', 'pb', 'sh', 'dw', 'is', 'trn', 'sl', 'se', 'cs', 'cr', 'ap', 'aip', 'tra', 'cn', 'upt', 'rov'],
    }
  })
  salesResp = salesResp.data.response
  convertor.formatV2Resp(salesResp, params.frequency == 'daily' ? 'date' : 'hour')
  let retObj = []
  salesResp.kpis.forEach((itm) => {
    retObj.push({
      dateid: params.frequency == 'daily' ? itm._id?.date?.replace(/-/g, "") : itm._id.hour,
      storecode: itm._id.storeCode,
      traffic: itm.trf,
      sales: itm.sls,
      transactions: itm.trn,
      itemsold: itm.is,
      staffhours: itm.sh,
      passersby: itm.pb,
      dwelltime: itm.dw,
      stafflevel: itm.sl,
      staffefficiency: itm.se,
      capturerate: itm.cr,
      avgprice: itm.ap,
      avgItemPrice: itm.aip,
      upt: itm.upt,
      rov: itm.rov,
      conversion: itm.cn,
      targetAchievementRate: itm.tra,
      closingStrength: itm.cs,
      salesTarget: itm.st
    })
  })
 
}

async function fetchAllDetail(params) {
  let userKPIs = JSON.parse(localStorage.getItem('vuex'))?.user?.kpiPreferences?.filter((itm) => {
    return itm.visibility
  }).map((itm) => {
    return itm.metricKey
  })
  if(params.status && params.status == 'kpitree')
    {
     // userKPIs = params.kpis;
      delete params.status;
    }
  let allKpis = getAllRelatedKpis(userKPIs)
  let freq = params.startDate == params.endDate ? 'hourly' : 'daily';
  let dateT = freq == 'daily' ? 'date' : 'hour';
  let startDate =params.startDate;
  let endDate =params.endDate;
  let compareStartDate =params.compareStartDate;
  let compareEndDate =params.compareEndDate;

  let newParam = {
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    "storeCodes": params.storeCodes.split(','),
    kpiTypes: allKpis,
    "frequency": freq,
    days: params.daysOfWeek.split(","),
    summary: 1
  }
  // await fetchDataForQA(newParam)
  let resp1 = {};
  resp1 = await APIGetReportingSumByLocationDataFidaV2(newParam)
  console.time("getAllAPITIme")
  storeData = await getStoreData();
  if(!resp1?.data?.response) {
    return {};
  }
  resp1 = resp1.data.response
  fillEmptyData(resp1.kpis, {startDate, endDate, storeCodes: params.storeCodes.split(','), userKPIs, stores: resp1.stores || storeData.dataAccessStores, sDate:startDate})
  fillEmptyData(resp1.kpisCompare, {startDate: compareStartDate, endDate: compareEndDate, storeCodes: params.storeCodes.split(','), userKPIs, stores: resp1.stores || storeData.dataAccessStores, sDate:startDate})

  convertor.formatV2Resp(resp1, dateT)
  const [st1, d1] = convertor.getCount(resp1.kpis, dateT)
  const [st2, d2] = convertor.getCount(resp1.kpisCompare, dateT)
  let area = convertor.getArea(resp1.kpis, storeData)
  let cArea = convertor.getArea(resp1.kpisCompare, storeData)
  let compareData = await sortRespByLocation(params, ["regions", "districts", "stores", "peerGroups", "areaManagers"], JSON.parse(JSON.stringify(resp1)), { kpis: userKPIs })
  let trendR = JSON.parse(JSON.stringify(resp1))
  let stores = resp1.stores;
  let allTrends = {}
  const trendKPIs = JSON.parse(JSON.stringify(userKPIs))
  trendKPIs.push("targetrate")
  trendKPIs.forEach((t) => {
    let thisTrend = trendCalc.sumTrendKPI(trendR.kpis, trendR.kpisCompare, t.toLowerCase(), dateT, {area, cArea, userKPIs, startDate: params.startDate, compareStartDate: params.compareStartDate,}, stores)
    thisTrend.data.selectedPeriod = thisTrend.data.selectedPeriod.sort((a, b) => {
      return new Date(a.timeLabel).getTime() - new Date(b.timeLabel).getTime();
    });
    thisTrend.data.comparedPeriod = thisTrend.data.comparedPeriod.sort((a, b) => {
      return new Date(a.timeLabel).getTime() - new Date(b.timeLabel).getTime();
    });
    allTrends[thisTrend.compatibleKey] = thisTrend
  })

  let retVal;

  // if(resp1.kpisSummary && resp1.compareSummary && Object.keys(resp1.compareSummary).length>0) {
  //   retVal = convertor.getConvertedValuesSimplified(resp1.kpisSummary, resp1.compareSummary)
  // } else {
    let bValues = convertor.sumAll(resp1.kpis, freq == 'daily' ? 'date' : 'hour', area)
    let bCValues = convertor.sumAll(resp1.kpisCompare, freq == 'daily' ? 'date' : 'hour', cArea)
    retVal = convertor.getConvertedValues(bValues, bCValues, resp1.kpis, resp1.kpisCompare, { storeCount: params.storeCodes.split(',').length, area, cArea, st1, d1, st2, d2, userKPIs });  
  // }
  return { data: [{ kpis: retVal }], compareData, allTrends, params: newParam, stores: resp1.stores || storeData.dataAccessStores };
}

async function fetchOverviewforDetail(params) {
  let freq = params.frequency;
  let dateT = freq == 'daily' ? 'date' : 'hour'
  let newParam = {
    startDate: params.startDate,
    endDate: params.endDate,
    compareStartDate: params.compareStartDate,
    compareEndDate: params.compareEndDate,
    "storeCodes": params.storeCodes.split(','),
    kpiTypes:  params.kpiTypes.split(","),
    "frequency": freq,
    days: params.daysOfWeek.split(",")
  }

  let resp1 = {};
  resp1 = await APIGetReportingSumByLocationDataFidaV2(newParam)
  storeData = await getStoreData();
  resp1 = resp1.data.response
  convertor.formatV2Resp(resp1, dateT)
  const [st1, d1] = convertor.getCount(resp1.kpis, dateT)
  const [st2, d2] = convertor.getCount(resp1.kpisCompare, dateT)
  let area = convertor.getArea(resp1.kpis, storeData)
  let cArea = convertor.getArea(resp1.kpisCompare, storeData)
  let bValues = convertor.sumAll(resp1.kpis, freq == 'daily' ? 'date' : 'hour', area)
  let bCValues = convertor.sumAll(resp1.kpisCompare, freq == 'daily' ? 'date' : 'hour', cArea)
  let retVal = convertor.getConvertedValues(bValues, bCValues, resp1.kpis, resp1.kpisCompare, { storeCount: params.storeCodes.split(',').length, area, cArea, st1, d1, st2, d2, userKPIs: params.kpiTypes.split(",")  });


 return { data: [{ kpis: retVal}]};
}
// let kpiList = "sls,ap,trn,aip,upt,trf,cn,pb,cr,st";
async function fetchAllDetailForKpiTree(params) {
  let kpiList = localStorage.getItem("kpiList");//"sls,ap,trn,aip,upt,trf,cn,pb,cr,st";
  
  let userKPIs = kpiList.split(',');
  if(params.status && params.status == 'kpitree')
    {
     // userKPIs = params.kpis;
      delete params.status;
    }
  let allKpis = getAllRelatedKpis(userKPIs)
  let freq = params.startDate == params.endDate ? 'hourly' : 'daily';
  let dateT = freq == 'daily' ? 'date' : 'hour';
  let startDate =params.startDate;
  let endDate =params.endDate;
  let compareStartDate =params.compareStartDate;
  let compareEndDate =params.compareEndDate;

  let newParam = {
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    "storeCodes": params.storeCodes.split(','),
    kpiTypes: allKpis,
    "frequency": freq,
    days: params.daysOfWeek.split(","),
    summary: 1
  }
  // await fetchDataForQA(newParam)
  let resp1 = {};
  resp1 = await APIGetReportingSumByLocationDataFidaV2(newParam)
  console.time("getAllAPITIme")
  storeData = await getStoreData();
  if(!resp1?.data?.response) {
    return {};
  }
  resp1 = resp1.data.response

  fillEmptyData(resp1.kpis, {startDate, endDate, storeCodes: params.storeCodes.split(','), userKPIs, stores: resp1.stores || storeData.dataAccessStores})
  fillEmptyData(resp1.kpisCompare, {startDate: compareStartDate, endDate: compareEndDate, storeCodes: params.storeCodes.split(','), userKPIs, stores: resp1.stores || storeData.dataAccessStores})

  convertor.formatV2Resp(resp1, dateT)
  const [st1, d1] = convertor.getCount(resp1.kpis, dateT)
  const [st2, d2] = convertor.getCount(resp1.kpisCompare, dateT)
  let area = convertor.getArea(resp1.kpis, storeData)
  let cArea = convertor.getArea(resp1.kpisCompare, storeData)
  let compareData = await sortRespByLocation(params, ["regions", "districts", "stores", "peerGroups", "areaManagers"], JSON.parse(JSON.stringify(resp1)), { kpis: userKPIs })
  let trendR = JSON.parse(JSON.stringify(resp1))
  let allTrends = {}
  const trendKPIs = JSON.parse(JSON.stringify(userKPIs))
  trendKPIs.push("targetrate")
  trendKPIs.forEach((t) => {
    let thisTrend = trendCalc.sumTrendKPI(trendR.kpis, trendR.kpisCompare, t.toLowerCase(), dateT, {area, cArea, userKPIs, startDate: params.startDate, compareStartDate: params.compareStartDate,})
    thisTrend.data.selectedPeriod = thisTrend.data.selectedPeriod.sort((a, b) => {
      return new Date(a.timeLabel).getTime() - new Date(b.timeLabel).getTime();
    });
    thisTrend.data.comparedPeriod = thisTrend.data.comparedPeriod.sort((a, b) => {
      return new Date(a.timeLabel).getTime() - new Date(b.timeLabel).getTime();
    });
    allTrends[thisTrend.compatibleKey] = thisTrend
  })
  let retVal;

  // if(resp1.kpisSummary && resp1.compareSummary && Object.keys(resp1.compareSummary).length>0) {
  //   retVal = convertor.getConvertedValuesSimplified(resp1.kpisSummary, resp1.compareSummary)
  // } else {
    let bValues = convertor.sumAll(resp1.kpis, freq == 'daily' ? 'date' : 'hour', area)
    let bCValues = convertor.sumAll(resp1.kpisCompare, freq == 'daily' ? 'date' : 'hour', cArea)
    retVal = convertor.getConvertedValuesForKpiTree(bValues, bCValues, resp1.kpis, resp1.kpisCompare, { storeCount: params.storeCodes.split(',').length, area, cArea, st1, d1, st2, d2, userKPIs });  
  // }localStorage.getItem("kpiList");

  console.timeEnd("getAllAPITIme")
  return { data: [{ kpis: retVal }], compareData, allTrends, params: newParam };
}
// async function fetchDistribution(params) {
//   let freq = params.frequency;
//   let dateT = freq == 'daily' ? 'date' : 'hour'
//   let newParam = {
//     startDate: params.startDate,
//     endDate: params.endDate,
//     compareStartDate: params.compareStartDate,
//     compareEndDate: params.compareEndDate,
//     "storeCodes": params.storeCodes,
//     kpiTypes:  params.kpiTypes.split(','),
//     "frequency": freq,
//   }
//   let resp1 = {};
//   resp1 = await APIGetReportingSumByLocationDataFidaV2(newParam)
//   storeData = await getStoreData();
//   resp1 = resp1.data.response;
//   convertor.formatV2Resp(resp1, dateT)
//   const [st1, d1] = convertor.getCount(resp1.kpis, dateT)
//   const [st2, d2] = convertor.getCount(resp1.kpisCompare, dateT)
//   let area = convertor.getArea(resp1.kpis, storeData)
//   let cArea = convertor.getArea(resp1.kpisCompare, storeData)
//   let bValues = convertor.sumAll(resp1.kpis, freq == 'daily' ? 'date' : 'hour', area)
//   let bCValues = convertor.sumAll(resp1.kpisCompare, freq == 'daily' ? 'date' : 'hour', cArea)
//   debugger;
//   let retVal = convertor.getConvertedValuesByPeriod(bValues, bCValues, resp1.kpis, resp1.kpisCompare, { storeCount: params.storeCodes.length, area, cArea, st1, d1, st2, d2, userKPIs: params.kpiTypes.split(",") });
//   debugger;
//   console.log("fetchDistribution", retVal)

//   return { data: retVal};
// }


async function fetchSingleKpi(params) {
  let freq = params.startDate == params.endDate ? 'hourly' : 'daily';
  params.frequency = freq;
  let resp1 = {};
  resp1 = await APIGetReportingSumByLocationDataFidaV2(params)
  resp1 = resp1.data.response
  convertor.formatV2Resp(resp1, freq == 'daily' ? 'date' : 'hour')

  return { res: resp1 };
}

async function sortRespByLocation(params, types, resp, extData) {
  let retVal = {}
  storeData = await getStoreData();

  types.forEach((type) => {
    let storeGroupsByStoreCodeList;
    let allStores = ""
    let typedStores = {}
    if(type == "districts") {

      storeGroupsByStoreCodeList = getStoreGroupsByStoreCodeList();
      let names = storeGroupsByStoreCodeList[type].split(',').map(name => name.trim());
      
      for (let i = 0; i < names.length; i++) {
        let cS = getDistrictStoreCodes(storeData, type, names[i]) + ",";
        allStores += cS;
        typedStores[names[i]] = cS
      }
    } else if (type != "stores") {

      storeGroupsByStoreCodeList = getStoreGroupsByStoreCodeList();


      let names = storeGroupsByStoreCodeList[type].split(',').map(name => name.trim());

      for (let i = 0; i < names.length; i++) {
        let cS = getStoreCodesByLocations(type, names[i]) + ",";
        allStores += cS;
        typedStores[names[i]] = cS
      }
    } else {
      allStores = params.storeCodes
      params.storeCodes.split(',').forEach((s) => {
        typedStores[s] = s
      })
    }

    let area = convertor.getArea(resp.kpis, storeData)

    retVal[type] = fidaToOldConvertor.formatComparisionByLocation(resp, typedStores, type, params.comparisonYKPIMetricKey, storeNamesbyCode, area, extData)
  })

  return retVal
}

async function getComparisionByLocation(params, types) {
  const userKPIs = JSON.parse(localStorage.getItem('vuex'))?.user?.kpiPreferences?.filter((itm) => {
    return itm.visibility
  }).map((itm) => {
    return itm.metricKey
  })
  
  let allKpis = getAllRelatedKpis(userKPIs)
  let freq = params.startDate == params.endDate ? 'hourly' : 'daily';
  let dateT = freq == 'daily' ? 'date' : 'hour';
  let newParam = {
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    "storeCodes": params.storeCodes.split(','),
    kpiTypes: allKpis,
    "frequency": freq,
    days: params.daysOfWeek.split(",")
  }
  // await fetchDataForQA(newParam)
  let resp1 = {};
  resp1 = await APIGetReportingSumByLocationDataFidaV2(newParam)
  console.time("getAllAPITIme")
  storeData = await getStoreData();
  resp1 = resp1.data.response
  convertor.formatV2Resp(resp1, dateT)

  let startDate =params.startDate;
  let endDate =params.endDate;
  let compareStartDate =params.compareStartDate;
  let compareEndDate =params.compareEndDate;
  
  fillEmptyData(resp1.kpis, {startDate, endDate, storeCodes: params.storeCodes.split(','), userKPIs, stores: resp1.stores || storeData.dataAccessStores})
  fillEmptyData(resp1.kpisCompare, {startDate: compareStartDate, endDate: compareEndDate, storeCodes: params.storeCodes.split(','), userKPIs, stores: resp1.stores || storeData.dataAccessStores})
  
  let compareData = await sortRespByLocation(params, types, resp1, { kpis: userKPIs })
  return { description: "Success", status: "SUCCESS", data: compareData }
}
export default {
  fetchAllDetail,
  getComparisionByLocation,
  fetchSingleKpi,
  // fetchDistribution,
  fetchOverviewforDetail,
  fetchAllDetailForKpiTree
}
