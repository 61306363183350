import axios from 'axios';
import store from '@/store';
import {
  SSOPutRequest,
  SSOPutRequest2,
  SSOPostRequest2,
  SSODeleteRequest2,
  SSOGetRequest2
} from '@/util/kpis';
import { fetchClass } from '@/plugins/fetch';
import config from '@/config';

var sso = config.sso;
export default {
  // APIFetchStores() {
  //   const paramsData = {
  //       client_id: store.state?.clientId,
  //       client_secret: store.state?.clientSecret,
  //     };
  //   return axios.get(store.state?.baseKpiUrl+'/api/locationHierarchy',{
  //     params: paramsData,
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  async APIFetchStores() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret
    };
    const apiResponse = await SSOGetRequest2('/api/locationHierarchy', {
      params: paramsData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + token
      }
    });
    return apiResponse;
  },
  async ApiGetImage(name) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    var url = `${sso}api/getUploadedContentUrl?filename=${name}`;
    
    const response = await axios.get(url, {headers: {
      Authorization:
        "Bearer"+token
    }});
    return response;
  },
  async getStoreLogs(id) {
      const storeState = store.state;
      const token = storeState.user.sessionClient.access_token;
      const paramsData = {
        client_id: storeState.user?.clientId,
        client_secret: storeState.user?.clientSecret
      };
      const apiResponse = await SSOGetRequest2(`api/getStoreLogs?_id=${id}`, {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      });
      return apiResponse;
  
  },
  // getStoreDetail() {
  //   const paramsData = {
  //       client_id: store.state?.clientId,
  //       client_secret: store.state?.clientSecret,
  //     };
  //   return axios.get(store.state?.baseKpiUrl+'/api/getStores',{
  //     params: paramsData,
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  async getStores(bool) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      ...(bool && { flag: 1 })
    };
    // if(Object.keys(params).length){
    //   paramsData['pageNumber'] = params.pageNumber
    //   paramsData['perPage'] = params.perPage
    //   paramsData['searchText'] = params.searchText
    //   paramsData['sortDesc'] = 'createdAt'
    // }
    const apiResponse = await SSOGetRequest2('/api/getStores', {
      params: paramsData,
      headers: {
        Authorization: 'Bearer' + token
      }
    });
    return apiResponse;
  },
  // async getStoredetail() {
  //   const storeState = store.state;
  //   const token = storeState.user.sessionClient.access_token
  //   const paramsData = {
  //     client_id: storeState.user?.clientId,
  //     client_secret: storeState.user?.clientSecret,

  //   }
  //   const apiResponse = await SSOGetRequest2(
  //     '/api/getStoreDetail',
  //     {
  //       params: paramsData,
  //       headers: {
  //         Authorization: 'Bearer' + token
  //       }
  //     }
  //   );
  //   return apiResponse
  // },
  // getUserStores() {
  //   const paramsData = {
  //       client_id: store.state?.clientId,
  //       client_secret: store.state?.clientSecret
  //     };
  //   return axios.get(store.state?.baseKpiUrl+"/api/getMyStores", {
  //     params: paramsData,
  //     headers: {
  //       Authorization:
  //         "Bearer "+store.state?.token
  //     },
  //   })
  // },
  async getUserStores() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret
    };
    const apiResponse = await SSOGetRequest2('/api/getMyStores', {
      params: paramsData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + token
      }
    });
    return apiResponse;
  },
  // assignUserStore(details) {
  //   let client_id =  store.state?.clientId
  //   let client_secret = store.state?.clientSecret
  //   let userData = {
  //     "client_id": client_id,
  //     "client_secret": client_secret,
  //     "storeId": details.storeId,
  //     "_id": details.userId
  //   };
  //   return axios.post(store.state?.baseKpiUrl+'/api/assignUserStore', userData, {
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token,
  //     },
  //   })
  // },
  async assignUserStore(details) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    let userData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      storeId: details.storeId,
      _id: details.userId
    };
    const apiResponse = await SSOPostRequest2(
      '/api/assignUserStore',
      userData,
      {
        // params: userData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse;
  },
  // getStoreTypes() {
  //   const paramsData = {
  //       client_id: store.state?.clientId,
  //       client_secret: store.state?.clientSecret,
  //     };
  //   return axios.get(store.state?.baseKpiUrl+'/api/getStoreTypes',{
  //     params: paramsData,
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  async getStoreTypes() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret
    };
    const apiResponse = await SSOGetRequest2('/api/getStoreTypes', {
      params: paramsData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + token
      }
    });
    return apiResponse;
  },
  // createStoreType(details) {
  //   const paramsData = {
  //       "client_id": store.state?.clientId,
  //       "client_secret": store.state?.clientSecret,
  //       "types": details,
  //     };
  //   return axios.post(store.state?.baseKpiUrl+'/api/createStoreType',paramsData,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  async createStoreType(details) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    let userData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      types: details
    };
    const apiResponse = await SSOPostRequest2(
      '/api/createStoreType',
      userData,
      {
        // params: userData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse;
  },
  // updateStoreType(details) {
  //   const paramsData = {
  //       "client_id": store.state?.clientId,
  //       "client_secret": store.state?.clientSecret,
  //       "typeId": details.typeId,
  //       "title": details.title,
  //       "description": details.description
  //     };
  //   return axios.put(store.state?.baseKpiUrl+'/api/updateStoreType',paramsData,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  async updateStoreType(details) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      typeId: details.typeId,
      title: details.title,
      description: details.description
    };
    const apiResponse = await SSOPutRequest2(
      '/api/updateStoreType',
      paramsData,
      {
        // params: paramsData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse;
  },
  async deleteStoreType(details) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      typeId: details
    };
    const apiResponse = await SSODeleteRequest2('/api/deleteStoreType', {
      data: paramsData,
      headers: {
        Authorization: 'Bearer' + token
      }
    });
    return apiResponse;
  },
  // deleteStoreType(details) {
  //   const paramsData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "typeId": details,
  //   };
  //   return axios.delete(store.state?.baseKpiUrl + '/api/deleteStoreType', {
  //     data: paramsData,
  //     headers: {
  //       Authorization:
  //         "Bearer" + store.state?.token
  //     },
  //   })
  // },
  // unassignUserStore(details) {
  //   let client_id =  store.state?.clientId
  //   let client_secret = store.state?.clientSecret
  //   let userData = {
  //     "client_id": client_id,
  //     "client_secret": client_secret,
  //     "storeId": details.storeId,
  //     "_id": details.userId
  //   };
  //   return axios.post(store.state?.baseKpiUrl+'/api/unassignUserStore', userData, {
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token,
  //     },
  //   })
  // },
  async unassignUserStore(details) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    let userData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      storeId: details.storeId,
      _id: details.userId
    };
    const apiResponse = await SSOPostRequest2(
      '/api/unassignUserStore',
      userData,
      {
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse;
  },
  //  createStore(details) {
  //   const paramsData = {
  //       "client_id": store.state?.clientId,
  //       "client_secret": store.state?.clientSecret,
  //       "level": details.level,
  //       "title": details.title,
  //       "storeNumber": details.storeNumber,
  //       "storeBrand": details.storeBrand,
  //       "storeType": details.storeType,
  //       "status": details.status,
  //       "storeArea": details.storeArea,
  //       "openingDate": details.openingDate,
  //       "country": details.country,
  //       "countryCode": details.countryCode,
  //       "region": details.region,
  //       "area": details.area,
  //       "address": details.address,
  //       "storeTimings": details.storeTimings
  //     };
  //   return axios.post(store.state?.baseKpiUrl+'/api/createStore',paramsData,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  async createStore(details) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      level: details.level,
      title: details.title,
      storeNumber: details.storeNumber,
      brands: details.storeBrand?.split(),
      types: details.storeType?.split(),
      status: details.status,
      area: details.storeArea,
      openDate: details.openingDate,
      closeDate: details.closingDate,
      cityId: details.area,
      address: details.address,
      // "phone": parseInt(details.companyPhone),
      storeTimings: details.storeTimings,
      locationDetails: {
        country: details.country?.toString(),
        region: details.region?.toString(),
        municipality: details.area?.toString(),
        prefecture: details.prefecture,
        district: details.district,
        phone: details.companyPhone
      }
    };

    const apiResponse = await SSOPostRequest2('/api/createStore', paramsData, {
      // params: queryParams,
      headers: {
        Authorization: 'Bearer' + token
      }
    });
    return apiResponse;
  },
  // updateStore(details) {
  //   const paramsData = {
  //       "client_id": store.state?.clientId,
  //       "client_secret": store.state?.clientSecret,
  //       "storeId": details.storeId,
  //       "level": details.level,
  //       "title": details.title,
  //       "storeNumber": details.storeNumber,
  //       "storeBrand": details.storeBrand,
  //       "storeType": details.storeType,
  //       "status": details.status,
  //       "storeArea": details.storeArea,
  //       "openingDate": details.openingDate,
  //       "country": details.country,
  //       "countryCode": details.countryCode,
  //       "region": details.region,
  //       "area": details.area,
  //       "address": details.address,
  //       "storeTimings": details.storeTimings
  //     };
  //   return axios.put(store.state?.baseKpiUrl+'/api/updateStore',paramsData,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  async updateStore(details, upWtLocation) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      storeId: details.storeId,
      level: details.level,
      title: details.title,
      storeNumber: details.storeNumber,
      brands: details.storeBrand?.split(),
      types: details.storeType?.split(),
      status: details.status,
      area: details.storeArea,
      openDate: details.openingDate,
      closeDate: details.closingDate,
      cityId: details.area,
      address: details.address,
      storeTimings: details.storeTimings,
      // "phone": details.companyPhone,
      ...(!upWtLocation &&
        {locationDetails: {
          country: details.country?.toString(),
          // "countryCode": details.countryCode,
          region: details.region?.toString(),
          municipality: details.area?.toString(),
          prefecture: details.prefecture,
          district: details.district,
          phone: details.companyPhone
        }}
      )
    };
    const apiResponse = await SSOPutRequest2('/api/updateStore', paramsData, {
      // params: paramsData,
      headers: {
        Authorization: 'Bearer' + token
      }
    });
    return apiResponse;
  },
  async updateSensor(details) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      storeId: details.storeId,
      devices: details.deviceInfo
    };
    const apiResponse = await SSOPutRequest2('/api/updateStore', paramsData, {
      // params: paramsData,
      headers: {
        Authorization: 'Bearer' + token
      }
    });
    return apiResponse;
  },
  async uploadFile(formData) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    var url = `${sso}api/uploadContent`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer' + token,
      },
      body: formData
    });
    return response;
    // return fetchClass(
    //   `${sso}api/uploadContent`,
    //   {
    //     method: 'PUT',
    //     body: formData,
    //     headers: {
    //         Authorization: 'Bearer' + token,
    //         'Content-Type': 'multipart/form-data',
    //     },
    //   },
    //   true
    // );
    // const storeState = store.state;
    // const token = storeState.user.sessionClient.access_token
    // let paramsData = {
    //   client_id: storeState.user?.clientId,
    //   client_secret: storeState.user?.clientSecret,
    // //   storeId:details.storeId,
    // //   devices:details.deviceInfo
    // };
    // const apiResponse = await SSOPutRequest2(
    //   `${sso}/uploadContent`, formData,
    //   {
    //     // params: paramsData,
    //     headers: {
    //       Authorization: 'Bearer' + token
    //     }
    //   }
    // );
    // return apiResponse
  },
  async updateDeviceData(details) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      ...details
    };
    const apiResponse = await SSOPutRequest(
      '/api/updateDeviceData',
      paramsData,
      {
        // params: paramsData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse;
  },
  async deleteStore(details) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token;
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      storeId: details
    };
    const apiResponse = await SSODeleteRequest2('/api/deleteStore', {
      data: paramsData,
      headers: {
        Authorization: 'Bearer' + token
      }
    });
    return apiResponse;
  }
  // deleteStore(details) {
  //   const paramsData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "storeId": details,
  //   };
  //   return axios.delete(store.state?.baseKpiUrl + '/api/deleteStore', {
  //     data: paramsData,
  //     headers: {
  //       Authorization:
  //         "Bearer" + store.state?.token
  //     },
  //   })
  // },
};