
  import { mapState, mapActions, mapGetters } from "vuex";
  import { DUO_TONES, MONO_TONE } from "../../util/colors";
  import moment from "moment";
  import { weatherObj } from "../../util/weatherIcons";
  import { addSign } from '@/util/replaceFidaKpi';
  
  export default {
    props: {
      options: {
        type: Object,
        required: false,
        default: () => {},
      }, // options
    }, // props
  
    data: function () {
      return {
        loading: false,
        fida: "true",
        series: [],
        selectedIcon: [],
        comparedIcon: [],
        minTemp: [],
        maxTemp: [],
        minTemp2: [],
        maxTemp2: [],
        selectedIcon2: [],
        comparedIcon2: [],
        weatherData: [],
        formattedSelected: [],
        formattedCompared: [],
        selectedPeriodTimeLabel: [],
        comparedPeriodTimeLabel: [],
        chartOptions: {
          chart: {
            height: 230,
            type: "line",
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: '<i class="fas fa-download"></i>',
                selection: false,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: false,
                reset: true,
                customIcons: [],
              },
              autoSelected: "zoom",
            },
          },
          colors: DUO_TONES,
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 2,
            curve: "straight",
            dashArray: [0, 6],
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            markers: {
              radius: 0,
              width: 16,
              height: 4,
              customHTML: function () {
                return `<style>
  .apexcharts-legend-series:nth-child(1) .custom-marker{
    display:none;
  }
  </style>
                          <div class="custom-marker" style="position:absolute; width:4px; height:6px; left:0;top:-2px;background:white"></div>
                        <div class="custom-marker" style="position:absolute; width:4px; height:6px; left:8px;top:-2px;background:white"></div>`;
              },
            },
            formatter: (seriesName, opts) => {
              return this.$t(seriesName);
            },
          },
          yaxis: {
            type: "number",
            tickAmount: 4,
            formatter: function (val) {
              return val + "%";
            },
          },
          xaxis: {
            format: "MMM DD",
            tickAmount: 50,
            labels: {
              formatter: (val) => {
                let lang = this.$store.state.user.locale;
                return val?.length < 6
                  ? val
                  : moment(val)
                      .locale(lang)
                      .format(lang == "ja" ? "M月DD日" : "MMM DD");
              },
            },
          },
          tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              const getFullDay = (val) => {
                if (val === 0) {
                  return "Sun";
                }
                if (val === 1) {
                  return "Mon";
                }
                if (val === 2) {
                  return "Tue";
                }
                if (val === 3) {
                  return "Wed";
                }
                if (val === 4) {
                  return "Thu";
                }
                if (val === 5) {
                  return "Fri";
                }
                if (val === 6) {
                  return "Sat";
                }
              };
              return `
              <q-card class="flex column q-pa-md tooltip-container">
                <div class="flex justify-between">
                     <label>${
                       w.config.series[0].data[dataPointIndex]
                         ? w.config.series[0].data[dataPointIndex].z
                         : ""
                     }</label>
                        <div class="flex">
                        ${
                          this.getStoreCodesList.split(",").length
                            ? `<div class="flex q-ml-xl items-center justify-between weather-tooltip">
                              <div class="">
                                ${
                                  weatherObj[this.selectedIcon[dataPointIndex]]
                                    ? weatherObj[this.selectedIcon[dataPointIndex]]
                                    : ""
                                }
                              </div>
                                </div>
                                <div class="q-ml-xs text-bold">${
                                  this.maxTemp[dataPointIndex] &&
                                  this.maxTemp[dataPointIndex] != "NaN"
                                    ? this.maxTemp[dataPointIndex] + "°C"
                                    : ""
                                }</div>
                                <div class="q-ml-xs">${
                                  this.minTemp[dataPointIndex] &&
                                  this.minTemp[dataPointIndex] != "NaN"
                                    ? this.minTemp[dataPointIndex] + "°C"
                                    : ""
                                }</div>
                                `
                            : ""
                        }
                   </div>
  </div>
                  <div class="flex tooltip-title items-center">
                  <div class="tooltip-title-color" style="background:${
                    w.config.colors[0]
                  }"></div>
                    <label>${this.$t("selectedperiod")}:</label>
                    <div class="q-pl-md">${
                      this.formattedSelected[dataPointIndex]
                        ? addSign('trf',this.formattedSelected[dataPointIndex])
                        : ""
                    }</div>
                  </div>
                <div class="flex justify-between q-mt-md"><label for="">
                ${
                  w.config.series[1].data[dataPointIndex]
                    ? w.config.series[1].data[dataPointIndex].z
                    : ""
                }
                </label>
                <div class="flex">
                        ${
                          this.getStoreCodesList.split(",").length
                            ? `<div class="flex q-ml-xl items-center justify-between weather-tooltip">
                              <div class="">
                                ${
                                  weatherObj[this.selectedIcon2[dataPointIndex]]
                                    ? weatherObj[this.selectedIcon2[dataPointIndex]]
                                    : ""
                                }
                              </div>
                                </div>
                                <div class="q-ml-sm text-bold">${
                                  this.maxTemp2[dataPointIndex] &&
                                  this.maxTemp2[dataPointIndex] != "NaN"
                                    ? this.maxTemp2[dataPointIndex] + "°C"
                                    : ""
                                }</div>
                                  <div class="q-ml-sm">${
                                    this.minTemp2[dataPointIndex] &&
                                    this.minTemp2[dataPointIndex] != "NaN"
                                      ? this.minTemp2[dataPointIndex] + "°C"
                                      : ""
                                  }</div>
                                  `
                            : ""
                        }
                   </div>
                </div>
                  <div class="flex tooltip-title items-center">
                  <div class="tooltip-title-color" style="background:${
                    w.config.colors[1]
                  }"></div>
                    <label>${this.$t("compareperiod")}:</label>
                    <div class="q-pl-md">${
                      this.formattedCompared[dataPointIndex]
                        ? addSign('trf',this.formattedCompared[dataPointIndex])
                        : ""
                    }</div>
                  </div>
              </q-card>`;
            },
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        },
      };
    }, // data
  
    computed: {
      ...mapGetters("filter", [
        "getStoreCodesList",
        "getDaysOfWeekList",
        "getStartingPeriod",
        "getEndingPeriod",
        "getStartingCompared",
        "getEndingCompared",
        "getCustomCalenderState",
      ]), // mapGetters
      ...mapGetters("user", [
        "getStoresCodeNameList",
      ]), // mapGetters
  
      ...mapState("filter", ["startingPeriod", "endingPeriod"]),
      // ...mapState('traffic', ['selectedPeriod', 'comparedPeriod']),
      // ...mapState('trend', ['selectedPeriod', 'comparedPeriod']),
      mappedStateProperties() {
        return this.$store.state.traffic;
      },
      selectedPeriod() {
        return this.mappedStateProperties.selectedPeriod;
      },
      comparedPeriod() {
        return this.mappedStateProperties.comparedPeriod;
      },
  
      ...mapState("traffic", ["apiCall2"]),
      ...mapState("trend", ["apiCall"]),
      // Always map state properties from the 'weather' module
      ...mapState("weather", ["weather", "weatherCompare"]),
    },
  
    // computed
    watch: {
      apiCall2: {
        handler(newValue, oldValue) {
          this.addWeather();
        },
      },
      weather: {
        handler(newH) {
          this.addWeather();
        },
      },
      weatherCompare:{
        handler(newH){
          this.addWeather();
        }
      },
    }, // computed
    async created() {
      this.chartOptions.chart.toolbar = {
        ...this.chartOptions.chart.toolbar,
        export: {
          svg: {
            filename: `${this.$t("trafficTrend")} ${this.$t("KpiAnalysis.selected")}꞉ ${
              this.getStartingPeriod
            }˷${this.getEndingPeriod}  ${this.$t("KpiAnalysis.compared")}꞉ ${
              this.getStartingCompared
            }˷${this.getEndingCompared}`,
          },
          png: {
            filename: `${this.$t("trafficTrend")} ${this.$t("KpiAnalysis.selected")}꞉ ${
              this.getStartingPeriod
            }˷${this.getEndingPeriod}  ${this.$t("KpiAnalysis.compared")}꞉ ${
              this.getStartingCompared
            }˷${this.getEndingCompared}`,
          },
          csv: {
            filename: `${this.$t("trafficTrend")} ${this.$t("KpiAnalysis.selected")}꞉ ${
              this.getStartingPeriod
            }˷${this.getEndingPeriod}  ${this.$t("KpiAnalysis.compared")}꞉ ${
              this.getStartingCompared
            }˷${this.getEndingCompared}`,
          },
        },
      };
      try {
        this.loading = true;
  
        await this.fetchTrafficOverViewAndTrend({
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          daysOfWeek: this.getDaysOfWeekList,
          storeCodes: this.getStoreCodesList,
          mode: "fetchTrend",
          startComparedDate: this.getStartingCompared,
          endComparedDate: this.getEndingCompared,
          useCustomCalender: this.getCustomCalenderState,
        });
  
        // this.furtherMethod();
        this.addWeather();
      } catch (error) {
        this.$q.notify({
          type: "negative",
          position: "top",
          message: `Comparison Error: ${error}`,
        });
      } finally {
        this.loading = false;
      }
    }, // created
    methods: {
      ...mapActions("traffic", ["fetchTrafficOverViewAndTrend"]),
      ...mapActions("trend", ["fetchTrend", "fetchTrendHour"]),
      async furtherMethod() {
        const lang = this.$store.state.user.locale;
        this.selectedIcon = [];
        this.maxTemp = [];
        this.minTemp = [];
        //
        this.selectedIcon2 = [];
        this.maxTemp2 = [];
        this.minTemp2 = [];
        const data = [
          {
            name: "Current",
            data: [],
          },
          {
            name: "Compared",
            data: [],
          },
        ];
  
        this.weatherData = this.selectedPeriod.map((v) => v.weather);
        let selectedPeriod = this.selectedPeriod;
        let comparedPeriod = this.comparedPeriod;
        const checkC = comparedPeriod?.every(i => i.value == 0)
        if(this.getStartingPeriod == this.getEndingPeriod && checkC){
          let oDates = [];
          this.getStoreCodesList?.split(',')?.forEach(storeCode => {
            const storeList = this.getStoresCodeNameList?.find(st => st.storeCode === storeCode);
            const t = storeList?.storeTimings?.find(timing => timing.dates == this.getStartingPeriod ||timing.date == this.getStartingPeriod);
            let o=10, c=20;
              if(t) {
                o = Number(t.openingTime.split(":")[0])
                c = Number(t.closingTime.split(":")[0])
                for(let i=o; i<= c; i++) {
                  oDates.push(i.toString());
                }  
              }
          })
          if(oDates.length){
            selectedPeriod = selectedPeriod.filter(key => oDates.includes(key.timeLabel));
            comparedPeriod = comparedPeriod.filter(key => oDates.includes(key.timeLabel));
          }
        }
        //
        await selectedPeriod.forEach((date) => {
          this.selectedIcon = [...this.selectedIcon, date.weatherIcon];
          this.maxTemp = [...this.maxTemp, date.maxTemp?.toFixed(2) || ""];
          this.minTemp = [...this.minTemp, date.minTemp?.toFixed(2) || ""];
          this.formattedSelected = [...this.formattedSelected, date.formattedValue];
          if (this.getStartingPeriod === this.getEndingPeriod) {
            data[0].data.push({
              x: date.timeLabel + ":00",
              y: parseFloat(date.value, 10),
              z: date.timeLabel + ":00",
            });
          } else {
            data[0].data.push({
              x: date.timeLabel,
              y: parseFloat(date.value, 10) ? parseFloat(date.value, 10) : 0,
              z:
                moment(date.timeLabel).locale(lang).format("ll") +
                " (" +
                this.$t(this.getFullDay(new Date(date.timeLabel).getDay())) +
                ")",
            });
          }
        });
        await comparedPeriod.forEach((date, index) => {
          this.selectedIcon2 = [...this.selectedIcon2, date.weatherIcon];
          this.maxTemp2 = [...this.maxTemp2, date.maxTemp?.toFixed(2) || ""];
          this.minTemp2 = [...this.minTemp2, date.minTemp?.toFixed(2) || ""];
          this.formattedCompared = [...this.formattedCompared, date.formattedValue];
          if (this.getStartingPeriod === this.getEndingPeriod) {
            data[1].data.push({
              x: this.selectedPeriod[index]
                ? this.selectedPeriod[index].timeLabel + ":00"
                : "",
              y: parseFloat(date.value, 10),
              z: this.selectedPeriod[index]
                ? this.selectedPeriod[index].timeLabel + ":00"
                : "",
            });
          } else {
            data[1].data.push({
              x: this.selectedPeriod[index] ? this.comparedPeriod[index].timeLabel : "",
              y: parseFloat(date.value, 10) ? parseFloat(date.value, 10) : 0,
              z: this.selectedPeriod[index]
                ? moment(this.comparedPeriod[index].timeLabel).locale(lang).format("ll") +
                  " (" +
                  this.$t(
                    this.getFullDay(new Date(this.comparedPeriod[index].timeLabel).getDay())
                  ) +
                  ")"
                : "",
            });
          }
        });
        this.series = data;
        // if (this.getStartingPeriod !== this.getEndingPeriod) {
        //   const days = this.getDaysOfWeekList.split(",");
        //   this.series[0].data = this.series[0].data.filter((d) => {
        //     return days.some((day) => {
        //       return day.includes(moment(d.x.slice(0, 10)).format("dddd").toLowerCase());
        //     });
        //   });
        // }
      },
      addKeyWeather(apiDataArray, bool) {
        var wOrC = bool ? "weather" : "weatherCompare";
        var weatherObj = this.$store.state.weather[wOrC];
        return apiDataArray.map((obj) => {
          var wO = weatherObj?.find((ele) => ele.hasOwnProperty(obj.timeLabel))?.[
            obj.timeLabel
          ];
          return {
            ...obj,
            maxTemp: wO?.tempMax,
            minTemp: wO?.tempMin,
            weather: wO?.description,
            weatherIcon: wO?.icon,
            temperature: wO?.temp,
          };
        });
      },
      addWeather() {
        const selectedPeriod = this.addKeyWeather(this.selectedPeriod, true);
        const comparedPeriod = this.addKeyWeather(this.comparedPeriod, false);
        this.$store.commit("traffic/setSelectedPeriodTrend", { selectedPeriod });
        this.$store.commit("traffic/setComparedPeriodTrend", { comparedPeriod });
        this.furtherMethod();
      },
      getFullDay(val) {
        if (val === 0) {
          return "Sun";
        }
        if (val === 1) {
          return "Mon";
        }
        if (val === 2) {
          return "Tue";
        }
        if (val === 3) {
          return "Wed";
        }
        if (val === 4) {
          return "Thu";
        }
        if (val === 5) {
          return "Fri";
        }
        if (val === 6) {
          return "Sat";
        }
      },
    }, // methods
  }; // export default
  