import { ref } from 'vue';


export default {
  __name: 'ZoneImageUpload',
  setup(__props) {

const selectedZone = ref('Zone 1');
const zones = ref(['Zone 1', 'Zone 2', 'Zone 3', 'Zone 4']);

return { __sfc: true,selectedZone, zones }
}

}