// import { APIFetchTargets, APIFetchTargetsSales } from '@/api/target';
import dashboard from '@/api/apiDataManagers/dashboardManager';
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfYesterday,
  endOfYesterday,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addYears,
  addDays,
  addWeeks,
  subYears,
  addMonths,
  differenceInDays,
  getDay,

} from "date-fns";
import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';
import moment from 'moment'
import store from '@/store'
let brooksHourPerc = [0.07, 5.01, 9.63, 10.50, 11.19, 12.14, 12.90, 11.05, 9.95, 9.05, 6.56, 1.93, 0.02];
let rightonHourPerc = [5.41, 10.36, 10.56, 11.29, 12.67, 12.59, 10.98, 8.51, 7.17, 5.87, 4.58, 0.01];
let stripeHourPerc = [5.11, 9.81, 10.28, 11.50, 12.40, 12.34, 10.63, 8.72, 7.98, 6.80, 4.43];
let eachHourPerc = [5, 10, 11, 13, 15, 14, 12, 10, 6, 3, 1];
const initialState = {
  targetsPulse:{
    dailyTarget: {
    achieved: '0',
    targetFormatted: '0',
    target: '0',
    remainingFormatted: '0',
    remaining: '0',
    achievedFormatted: ''
  }, // dailyTarget
  monthlyTarget: {
    achieved: '0',
    targetFormatted: '0',
    target: '0',
    remainingFormatted: '0',
    remaining: '0',
    achievedFormatted: '0'
  }, // monthlyTarget},
  },
  targets: {
    dailyTarget: {
      achieved: '0',
      targetFormatted: '0',
      target: '0',
      remainingFormatted: '0',
      remaining: '0',
      achievedFormatted: ''
    }, // dailyTarget
    monthlyTarget: {
      achieved: '0',
      targetFormatted: '0',
      target: '0',
      remainingFormatted: '0',
      remaining: '0',
      achievedFormatted: '0'
    } // monthlyTarget
  }, // targets
  targetsPulseCompared: {
    dailyTarget: {
      achieved: '0',
      targetFormatted: '0',
      target: '0',
      remainingFormatted: '0',
      remaining: '0',
      achievedFormatted: ''
    }, // dailyTarget
    monthlyTarget: {
      achieved: '0',
      targetFormatted: '0',
      target: '0',
      remainingFormatted: '0',
      remaining: '0',
      achievedFormatted: '0'
    } // monthlyTarget
  },
  preYearData:null,
  callTracker: {}
}; // initialState

import axios from 'axios';
//check
let tenantPatterns = null;
async function getPattern() {
    if(tenantPatterns) {
      return tenantPatterns;
    }
    let resp = await axios.get('https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/localization/tenant_sales_pattern.json',{headers:{
        Authorization:''
    }})
    
    return resp;
  }

export default {
  state: initialState, // state
  mutations: {
    setTargets(state, { targets }) {
      state.targets = targets;
    }, // setTargets
    setTargetsPulse(state, { targetsPulse }) {
      state.targetsPulse = targetsPulse;
    },
    setTargetsPulseCompare(state, { targetsPulseCompared }) {
      state.targetsPulseCompared = targetsPulseCompared;
    },
    
    setTracker(state, { callerId, value }) {
      state.callTracker[callerId] = value;
    },
    setPrevYearData(state, payload) {
      state.preYearData = payload;
    },
  }, // mutations
  actions: {
    async fetchTargets({ commit, rootState, state }, payload) {
      
      if(payload.trackLatest) {
        commit('setTracker', payload.trackLatest);
      }
      let week = localStorage.getItem('WeekStartDay');
      // var week = rootState?.user?.defaultSettings?.weekStartDay;
      const weekStart = week && week?.toLowerCase()?.startsWith('mon') ? 1 : 0;
      //
      var getDate = (timeStamp) => {
        var date2 = new Date(timeStamp)
        var formate = moment(date2, 'DD-MM-YYYY')
        var mydate = formate.format('YYYY-MM-DD')
        return mydate
      }
      payload.client_id = rootState.user?.clientId,
      payload.client_secret = rootState.user?.clientSecret
      //
      var dt = new Date();
      var dtoday = new Date();
      payload.startDate = moment(addDays(startOfMonth(dt), -5)).format('YYYY-MM-DD');
      payload.todayDate = moment(dtoday).format('YYYY-MM-DD');
      payload.endDate = moment(addDays(endOfMonth(dtoday), 5)).format('YYYY-MM-DD');
      let cDate = addYears(new Date(), -1)
      let cSt = moment(addDays(startOfMonth(cDate), -5)).format('YYYY-MM-DD');
      let cEt = moment(addDays(endOfMonth(cDate), 5)).format('YYYY-MM-DD');
      let days = rootState.filter.filter.daysOfWeek;
      var res:any = await dashboard.fetchSingleKpi({startDate: payload.startDate, endDate: payload.endDate, compareStartDate: cSt, compareEndDate: cEt,kpiTypes: payload.kpiTypes, storeCodes: payload.storeCodes, client_id: payload.client_id, client_secret: payload.client_secret, days});

      let storesWithTarget = {kpis: {}, kpisCompare: {}, keys: {}};
      res.res.kpis.forEach((kpi) => {
        if(kpi.st) {
          storesWithTarget.kpis[kpi._id.storeCode] = (storesWithTarget.kpis[kpi._id.storeCode] || 0) + kpi.st
        }
      })
      res.res.kpisCompare.forEach((kpi) => {
        if(kpi.st) {
          storesWithTarget.kpisCompare[kpi._id.storeCode] = (storesWithTarget.kpisCompare[kpi._id.storeCode] || 0) + kpi.st
        }
      })
      storesWithTarget.keys["kpis"] = Object.keys(storesWithTarget.kpis)
      storesWithTarget.keys["kpisCompare"] = Object.keys(storesWithTarget.kpisCompare)
      tenantPatterns = await getPattern();
      if(payload.trackLatest) {
        if(rootState.target.callTracker[payload.trackLatest.callerId] !== payload.trackLatest.value) {
          return false;
        }
      }

      var sumCalc = (arr, bool) => {
        var key = bool ? 'sls': 'st'
        return arr.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
      }
      function hasTarget() {
        var d = new Date();
        var startMonth = moment(startOfMonth(d)).format('YYYY-MM-DD');
        var endMonth = moment(endOfMonth(d)).format('YYYY-MM-DD');
        var targetSales = res.res.kpis.filter(v => new Date(startMonth).getTime() <= new Date(v._id.date).getTime()  && !(new Date(endMonth).getTime() < new Date(v._id.date).getTime()));
        return sumCalc(targetSales, false) > 0
      }
      let userHasTarget = hasTarget();
      let userTodaysExpect = 0;
      
      function getTargetSalesData(stD, enD, isPrev, type) {

        let targetSales;
        let sTime = new Date(stD).getTime();
        let eTime = new Date(enD).getTime();
        if(isPrev) {
          if(type == 1) { //PREVIOUS_YEAR_SAME_DAY_OF_WEEK
            const diffDays = differenceInDays(
              sTime,
              eTime
            );
            sTime = new Date(addDays(new Date(sTime), -364)).getTime();
            eTime = new Date(addDays(new Date(eTime), -364)).getTime();
          } else if(type == 2) {
            sTime = moment(new Date(sTime), "YYYY-MM-DD")
            .subtract(1, "year").valueOf();



            eTime = moment(new Date(eTime), "YYYY-MM-DD")
            .subtract(1, "year").valueOf();
          }
        }


        let kS = !isPrev ? "kpis": "kpisCompare"

        targetSales = res.res[kS].filter((v) => {
          let cTime = new Date(v._id.date).getTime()
          // if(userHasTarget) {
          //   return storesWithTarget.keys[kS].indexOf(v._id.storeCode) > -1 && sTime <=  cTime && cTime <= eTime
          // } else {
            return sTime <=  cTime && cTime <= eTime
          // }
          })
        return JSON.parse(JSON.stringify(targetSales))
      }

      function getTargetAchieved(ts1, ts2, ts3, tTar = 0) {
        let ss = sumCalc(ts1, true);
        let ts = sumCalc(ts2, !userHasTarget);
        let tsE = sumCalc(ts3, !userHasTarget);
        // console.log(tsE, tTar, Math.floor(tsE + tTar), '¥' + Math.floor(tsE + tTar).toLocaleString() || '0')
     
        return {
          achieved: ss,
          expectedTar: tsE + tTar,
          expectedTargetFormatted: getSignsLeftSide('sls') + Math.floor(tsE + tTar).toLocaleString() + getSignsRightSide('sls') || '0',
          achievedFormatted: getSignsLeftSide('sls') + ss.toLocaleString() + getSignsRightSide('sls') || '0',
          target: ts,
          targetFormatted: getSignsLeftSide('sls') + ts.toLocaleString() + getSignsRightSide('sls') || '0',
          userHasTarget
        }
      }

      function getTodayCurrentTarget(ts1, ts2) {
        let today = (new Date()).toLocaleDateString('en', { weekday: 'long' }).toLowerCase();
        let selStores = localStorage.getItem('storeCodes').split(',')
        let allStores = JSON.parse(localStorage.getItem('vuex'))?.user?.storesCodeNameList;
        let companyID = JSON.parse(localStorage.getItem('vuex'))?.user?.profile?.tenant?.intercom?.companyId;
        let o = 0;
        let e = 0;
        let c = 0

        selStores.forEach(s => {
          let timing = allStores?.filter((ss) => ss.storeCode == s)[0]
          ?.storeTimings?.filter((t) => t.day == today)[0]

          if(timing) {
            c++;
            o += Number(timing.openingTime.split(":")[0]);
            e += Number(timing.closingTime.split(":")[0]);
          }
        });
        if(c == 0) {
          o = 10;
          e = 19;
        } else {
          o /= c;
          e /= c;
        }
        o = Math.floor(o)
        e = Math.ceil(e)


        var tZ = new Date().getTimezoneOffset() * 60 * 1000;
        var currTime = new Date(Date.now() + tZ + 9 * 60 * 60 * 1000);
        let currH = currTime.getHours();

        let hourPerc;

        let pF = false;
        if(tenantPatterns[companyID]?.ja) {
          pF = true;
          hourPerc = tenantPatterns[companyID].ja
        } else {
          hourPerc = tenantPatterns["default_0"]?.ja;
        }

        if(!hourPerc) { hourPerc = eachHourPerc; } 
        else {
          hourPerc = hourPerc.split(',').map((a) => Number(a))
        }
        
        let expTar = 0;

        let ts = sumCalc(ts2, !userHasTarget);

        if(!pF) {
          let dV = 12;
          let hV = e - o + 1

          let diff = dV - hV;
          if(diff>0) {
            let sss = 0;
            for(let i=hV; i< dV; i++) {
              sss += hourPerc[i];
              hourPerc[i] = 0;
            }
            let dss = sss/hV;
            for(let i=0; i< hV; i++) {
              hourPerc[i] += dss;
            }
          } else if(diff <0) {
            for(let i=0; i< Math.abs(diff); i++) {
              hourPerc.push(0)
            }
          }
        }

        for(let i=o; i<= currH; i++) {
    //      expTar += ts * (hourPerc[i - o])/100
          let value = hourPerc[i - o] || 0;
          if (isNaN(value)) {
              value = 0;
          }
          expTar += ts * (value / 100);
        }

        // console.log("HourPerc", o, e, currH, hourPerc)
        return expTar;
      }

      var getDailySales = () => {
        let ts1 = getTargetSalesData(payload.todayDate, payload.todayDate, false, 1);
        let ts2 = getTargetSalesData(payload.todayDate, payload.todayDate, !userHasTarget, 1);
        userTodaysExpect = getTodayCurrentTarget(ts1, ts2)
        return getTargetAchieved(ts1, ts2, [], userTodaysExpect)
      }
      // //
      var getWeeklySales = () => {
        var d = new Date();
        let st = moment(startOfWeek(d,{weekStartsOn: weekStart})).format('YYYY-MM-DD');
        let et = moment(endOfWeek(d,{weekStartsOn: weekStart})).format('YYYY-MM-DD')
        const yesterday = endOfYesterday();
        let ts1 = getTargetSalesData(st, et, false, 1);
        let ts2 = getTargetSalesData(st, et, !userHasTarget, 1);
        let ts3 = getTargetSalesData(st, yesterday, !userHasTarget, 1);

        return getTargetAchieved(ts1, ts2, ts3, userTodaysExpect)
      }
      // //
      var getMonthlySales = () => {
        var d = new Date();
        let st = moment(startOfMonth(d)).format('YYYY-MM-DD');
        let et = moment(endOfMonth(d)).format('YYYY-MM-DD')
        const yesterday = endOfYesterday();
        let ts1 = getTargetSalesData(st, et, false, 2);
        let ts2 = getTargetSalesData(st, et, !userHasTarget, 2);
        let ts3 = getTargetSalesData(st, yesterday, !userHasTarget, 2);
        return getTargetAchieved(ts1, ts2, ts3, userTodaysExpect)
      }
      //

      
      commit('setTargets', {
        targets: {
          dailyTarget: {
            remainingFormatted: '0',
            remaining: '0',
            ...rootState.target.targets.dailyTarget,
            ...getDailySales(),

          }, // dailyTarget
          weeklyTarget: {
            remainingFormatted: '0',
            remaining: '0',
            ...rootState.target.targets.weeklyTarget,
            ...getWeeklySales(),

          }, // dailyTarget
          monthlyTarget: {
            remainingFormatted: '0',
            remaining: '0',
            ...rootState.target.targets.monthlyTarget,
            ...getMonthlySales(),
          }, // monthlyTarget
          isPreviousData: !userHasTarget
        }
      });

      return res;
    }, // fetchTargets
    //Month
    async fetchTargetsPulseMonth({ commit, rootState, state }, payload) {
      
 
      var week = rootState?.user?.startWeek;
      const weekStart = week == 'MONDAY' ? 1 : 0;

      payload.client_id = rootState.user?.clientId,
      payload.client_secret = rootState.user?.clientSecret

      let days = rootState.filter.filter.daysOfWeek;
      var res:any = await dashboard.fetchSingleKpi({startDate: payload.startDate, endDate: payload.endDate, kpiTypes: payload.kpiTypes, storeCodes: payload.storeCodes, client_id: payload.client_id, client_secret: payload.client_secret, days});

    
      var sumCalc = (arr, bool) => {
        var key = bool ? 'sls': 'st'
        return arr.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
      }
      // //
      var getMonthlySales = () => {
        var targetSales = res.res.kpis;
        return {
          achieved: sumCalc(targetSales, true),
          achievedFormatted: '¥' + sumCalc(targetSales, true).toLocaleString() || '0',
          target: sumCalc(targetSales, false),
          targetFormatted: '¥' + sumCalc(targetSales, false).toLocaleString() || '0'
        }
      }
      //
      
      commit('setTargetsPulse', {
        targetsPulse: {
          monthlyTarget: {
            remainingFormatted: '0',
            remaining: '0',
            ...rootState.target.targets.monthlyTarget,
            ...getMonthlySales(),
          } // monthlyTarget
        }
      });

      return res;
    },
    async fetchTargetsPulseCompareMonth({ commit, rootState, state }, payload) {
      
      var week = rootState?.user?.startWeek;
      const weekStart = week == 'MONDAY' ? 1 : 0;
      //
      
      payload.client_id = rootState.user?.clientId,
      payload.client_secret = rootState.user?.clientSecret

      let days = rootState.filter.filter.daysOfWeek;
      var res:any = await dashboard.fetchSingleKpi({startDate: payload.startDate, endDate: payload.endDate, kpiTypes: payload.kpiTypes, storeCodes: payload.storeCodes, client_id: payload.client_id, client_secret: payload.client_secret, days});
      
  var sumCalc = (arr, bool) => {
        var key = bool ? 'sls': 'st'
        return arr.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
      }
      
      // //
      var getMonthlySales = () => {

        var targetSales = res.res.kpis;
        return {
          achieved: sumCalc(targetSales, true),
          achievedFormatted: '¥' + sumCalc(targetSales, true).toLocaleString() || '0',
          target: sumCalc(targetSales, false),
          targetFormatted: '¥' + sumCalc(targetSales, false).toLocaleString() || '0'
        }
      }
      //
      
      
      commit('setTargetsPulseCompare', {
        targetsPulseCompared: {          
          monthlyTarget: {
            remainingFormatted: '0',
            remaining: '0',
            ...rootState.target.targets.monthlyTarget,
            ...getMonthlySales(),
          } // monthlyTarget
        }
      });

      return res;
    },
    async fetchTargetsPulse({ commit, rootState, state }, payload) {
      
      // if(payload.trackLatest) {
      //   commit('setTracker', payload.trackLatest);
      // }
      var week = rootState?.user?.startWeek;
      const weekStart = week == 'MONDAY' ? 1 : 0;
      //
      var getDate = (timeStamp) => {
        var date2 = new Date(timeStamp)
        var formate = moment(date2, 'DD-MM-YYYY')
        var mydate = formate.format('YYYY-MM-DD')
        return mydate
      }
      payload.client_id = rootState.user?.clientId,
      payload.client_secret = rootState.user?.clientSecret
      // //
      var dt = new Date();
      var dtoday = new Date();
      payload.startDate = moment(addDays(startOfMonth(dt), -5)).format('YYYY-MM-DD');
      payload.todayDate = moment(dtoday).format('YYYY-MM-DD');
      payload.endDate = moment(addDays(endOfMonth(dtoday), 5)).format('YYYY-MM-DD');
      let days = rootState.filter.filter.daysOfWeek;
      var res:any = await dashboard.fetchSingleKpi({startDate: payload.startDate, endDate: payload.endDate, kpiTypes: payload.kpiTypes, storeCodes: payload.storeCodes, client_id: payload.client_id, client_secret: payload.client_secret, days});

      // if(payload.trackLatest) {

      //   if(rootState.target.callTracker[payload.trackLatest.callerId] !== payload.trackLatest.value) {
      //     return false;
      //   }
      // }
      // targetsCompared,dailyTargetCompared,monthlyTargetCompared
      var sumCalc = (arr, bool) => {
        var key = bool ? 'sls': 'st'
        return arr.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
      }
      var getDailySales = () => {
        var targetSales = res.res.kpis.filter(v => payload.todayDate == v._id.date);
        return {
          achieved: sumCalc(targetSales, true),
          achievedFormatted: '¥' + sumCalc(targetSales, true).toLocaleString() || '0',
          target: sumCalc(targetSales,false),
          targetFormatted: '¥' + sumCalc(targetSales, false).toLocaleString() || '0'
        }
      }
      // //
      var getWeeklySales = () => {
        var d = new Date();
        var startWeek = moment(startOfWeek(d,{weekStartsOn: weekStart})).format('YYYY-MM-DD');
        var endWeek = moment(endOfWeek(d,{weekStartsOn: weekStart})).format('YYYY-MM-DD');
        var targetSales = res.res.kpis.filter(v => new Date(startWeek).getTime() <= new Date(v._id.date).getTime() && !(new Date(endWeek).getTime() < new Date(v._id.date).getTime()))
        return {
          achieved: sumCalc(targetSales, true),
          achievedFormatted: '¥' + sumCalc(targetSales, true).toLocaleString() || '0',
          target: sumCalc(targetSales, false),
          targetFormatted:  '¥' + sumCalc(targetSales, false).toLocaleString() || '0'
        }
      }
      // //
      var getMonthlySales = () => {
        var d = new Date();
        var startMonth = moment(startOfMonth(d)).format('YYYY-MM-DD');
        var endMonth = moment(endOfMonth(d)).format('YYYY-MM-DD');
        var targetSales = res.res.kpis.filter(v => new Date(startMonth).getTime() <= new Date(v._id.date).getTime()  && !(new Date(endMonth).getTime() < new Date(v._id.date).getTime()));
        return {
          achieved: sumCalc(targetSales, true),
          achievedFormatted: '¥' + sumCalc(targetSales, true).toLocaleString() || '0',
          target: sumCalc(targetSales, false),
          targetFormatted: '¥' + sumCalc(targetSales, false).toLocaleString() || '0'
        }
      }
      //
      
      commit('setTargetsPulse', {
        targetsPulse: {
          dailyTarget: {
            remainingFormatted: '0',
            remaining: '0',
            ...rootState.target.targets.dailyTarget,
            ...getDailySales(),

          }, // dailyTarget
          weeklyTarget: {
            remainingFormatted: '0',
            remaining: '0',
            ...rootState.target.targets.weeklyTarget,
            ...getWeeklySales(),

          }, // dailyTarget
          monthlyTarget: {
            remainingFormatted: '0',
            remaining: '0',
            ...rootState.target.targets.monthlyTarget,
            ...getMonthlySales(),
          } // monthlyTarget
        }
      });

      return res;
    },
    async fetchTargetsPulseCompare({ commit, rootState, state }, payload) {
      
      var week = rootState?.user?.startWeek;
      const weekStart = week == 'MONDAY' ? 1 : 0;
      //
      var getDate = (timeStamp) => {
        var date2 = new Date(timeStamp)
        var formate = moment(date2, 'DD-MM-YYYY')
        var mydate = formate.format('YYYY-MM-DD')
        return mydate
      }
      payload.client_id = rootState.user?.clientId,
      payload.client_secret = rootState.user?.clientSecret

      var dt = new Date();
      var dtoday = new Date();
      // for prev year data pulse
      if (payload.isPrevWeek) {
        dt = new Date(payload.startDate);
        dtoday = new Date(payload.startDate);
      }
      //
      dt = addMonths(dt, -1);
      dtoday = addMonths(dtoday, -1);
      payload.startDate = moment(addDays(startOfMonth(dt), -5)).format('YYYY-MM-DD');
      payload.todayDate = moment(dtoday).format('YYYY-MM-DD');
      payload.endDate = moment(addDays(endOfMonth(dtoday), 5)).format('YYYY-MM-DD');
      let days = rootState.filter.filter.daysOfWeek;
      var res:any = await dashboard.fetchSingleKpi({startDate: payload.startDate, endDate: payload.endDate, kpiTypes: payload.kpiTypes, storeCodes: payload.storeCodes, client_id: payload.client_id, client_secret: payload.client_secret, days});
      
      var sumCalc = (arr, bool) => {
        var key = bool ? 'sls': 'st'
        return arr.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
      }
      
      var getDailySales = () => {
        var targetSales = res.res.kpis.filter(v => payload.todayDate == v._id.date);
        return {
          achieved: sumCalc(targetSales, true),
          achievedFormatted: '¥' + sumCalc(targetSales, true).toLocaleString() || '0',
          target: sumCalc(targetSales,false),
          targetFormatted: '¥' + sumCalc(targetSales, false).toLocaleString() || '0'
        }
      }
      // //
      var getWeeklySales = () => {
        var d = new Date();
        d = addMonths(d, -1);
        var startWeek = moment(startOfWeek(d,{weekStartsOn: weekStart})).format('YYYY-MM-DD');
        var endWeek = moment(endOfWeek(d,{weekStartsOn: weekStart})).format('YYYY-MM-DD');
        var targetSales = res.res.kpis.filter(v => new Date(startWeek).getTime() <= new Date(v._id.date).getTime() && !(new Date(endWeek).getTime() < new Date(v._id.date).getTime()))
        return {
          achieved: sumCalc(targetSales, true),
          achievedFormatted: '¥' + sumCalc(targetSales, true).toLocaleString() || '0',
          target: sumCalc(targetSales, false),
          targetFormatted:  '¥' + sumCalc(targetSales, false).toLocaleString() || '0'
        }
      }
      // //
      var getMonthlySales = () => {
        var d = new Date();
        if(payload.isPrevWeek) { // for only preve year
            d = new Date(payload.startDate);
        }else{
          d = addMonths(d, -1);
        }
        var startMonth = moment(startOfMonth(d)).format('YYYY-MM-DD');
        var endMonth = moment(endOfMonth(d)).format('YYYY-MM-DD');
        var targetSales = res.res.kpis.filter(v => new Date(startMonth).getTime() <= new Date(v._id.date).getTime()  && !(new Date(endMonth).getTime() < new Date(v._id.date).getTime()));
        return {
          achieved: sumCalc(targetSales, true),
          achievedFormatted: '¥' + sumCalc(targetSales, true).toLocaleString() || '0',
          target: sumCalc(targetSales, false),
          targetFormatted: '¥' + sumCalc(targetSales, false).toLocaleString() || '0'
        }
      }
      //
      if(!payload.isPrevWeek){
        commit('setTargetsPulseCompare', {
          targetsPulseCompared: {
            dailyTarget: {
              remainingFormatted: '0',
              remaining: '0',
              ...rootState.target.targets.dailyTarget,
              ...getDailySales(),
  
            }, // dailyTarget
            weeklyTarget: {
              remainingFormatted: '0',
              remaining: '0',
              ...rootState.target.targets.weeklyTarget,
              ...getWeeklySales(),
  
            }, // dailyTarget
            monthlyTarget: {
              remainingFormatted: '0',
              remaining: '0',
              ...rootState.target.targets.monthlyTarget,
              ...getMonthlySales(),
            } // monthlyTarget
          }
        });
        return res;
      }else{
        commit("setPrevYearData", getMonthlySales());
      }
    },
  } // actions
}; // export default
function getPreviousPeriod(startDate1, endDate1,todayDate1) {
  // if (this.dateOptionsComparedModel.value === "PREVIOUS_PERIOD")
  var startDate = new Date(startDate1);
    var endDate = new Date(endDate1);
    var todaydate = new Date(todayDate1);

    const diffDays = differenceInDays(
      startDate,
      endDate
    );

    const diffDaysPositive = diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
    // const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
    const weeks = Math.ceil(Number((diffDaysPositive / 7).toFixed(2)));
   var previousStartDate = addWeeks(startDate, -weeks);
    var previousEndDate = addWeeks(endDate, -weeks);
    var previousTodayDate = addMonths(todaydate, -1);
    return {
          previousStartDate: formatDate(previousStartDate),
          previousEndDate: formatDate(previousEndDate),
          previousTodayDate: formatDate(previousTodayDate)
        };
  }
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }