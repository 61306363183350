
import { mapGetters, mapState } from 'vuex';
import * as XLSX from 'xlsx';

import { formatValues } from '@/api/apiDataManagers/calc_consts';
import moment from 'moment';
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['zone'],
  data() {
    return {
      columns: [
        {
          name: 'name',
          label: this.$store.state.user.translate.name,
          field: 'name',
          align: 'left',
          sortable: true,
          visible: true
        },
        {
          name: 'stops_selected',
          label: this.$store.state.user.translate.zone_stops_selected,
          field: 'stops_selected',
          visible: true
        },
        {
          name: 'stops_compared',
          label: this.$store.state.user.translate.zone_stops_compared,
          field: 'stops_compared',
          visible: true
        },
        {
          name: 'zone_pass_selected',
          label: this.$store.state.user.translate.zone_pass_seleted,
          field: 'zone_pass_selected',
          visible: true
        },
        {
          name: 'zone_pass_compared',
          label: this.$store.state.user.translate.zone_pass_compared,
          field: 'zone_pass_compared',
          visible: true
        },
        {
          name: 'zone_dwell_selected',
          label: this.$store.state.user.translate.zone_dwell_time_seleted,
          field: 'zone_dwell_selected',
          visible: true
        },
        {
          name: 'zone_dwell_compared',
          label: this.$store.state.user.translate.zone_dwell_time_compared,
          field: 'zone_dwell_compared',
          visible: true
        }
      ],
      rows: []
    };
  },

  computed: {
    ...mapState('store', ['stores']),
    ...mapGetters('filter', [
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared',
      'getStoreCodesList'
    ]),
    visibleColumns() {
      return this.columns.filter(col => col.visible);
    }
  },
  watch: {
    zone(newValue) {
      this.addZoneMethod();
    }
  },
  methods: {
    dateCount(dS, dE) {
      const startDate = moment(dS);
      const endDate = moment(dE);
      // const count = this.getStoreCodesList?.split(',').length || 0;
      const diffDays = endDate.diff(startDate, 'days') + 1;
      return diffDays;
    },
    addZoneMethod() {
      const { stores } = this.stores;
      const divid = this.dateCount(
        this.getStartingPeriod,
        this.getEndingPeriod
      );
      const rows = Object.keys(this.zone?.selected).map(key => {
        const { summary, zones } = this.zone['selected'][key];
        const { summary: summaryB, zones: zoneB } = this.zone['compared'][key];
        const findName = stores?.find(name => name.storeCode == key)?.name;
        // new code
         const  divideDwOnStops = summary?.dwell / summary?.stops;
         const  divideDwOnStopsB = summaryB?.dwell / summaryB?.stops;
        //
        return {
          name: findName ? `${key} - ${findName}` : key,
          stops_selected: `${summary?.stops?.toLocaleString()}人`,
          stops_compared: `${summaryB?.stops?.toLocaleString()}人`,
          zone_pass_selected: `${summary?.pb?.toLocaleString()}人`,
          zone_pass_compared: `${summaryB?.pb?.toLocaleString()}人`,
          zone_dwell_selected: formatValues(this.validateNumber(divideDwOnStops / divid), 'dw'),
          zone_dwell_compared: formatValues(this.validateNumber(divideDwOnStopsB / divid), 'dw'),
          expand: false,
          subZones:
            zones &&
            Object.keys(zones)?.map(k => {
              const zon = zones[k];
              const zonB = zoneB[k];
               // new code
                const  zndivideDwOnStops = zon?.dwell / zon?.stops;
                const  zndivideDwOnStopsB = zonB?.dwell / zonB?.stops;
                //
              return {
                name: findName ? `${findName} ${k}` : k,
                stops_selected: `${zon?.stops?.toLocaleString()}人`,
                stops_compared: `${zonB?.stops?.toLocaleString()}人`,
                zone_pass_selected: `${zon?.pb?.toLocaleString()}人`,
                zone_pass_compared: `${zonB?.pb?.toLocaleString()}人`,
                zone_dwell_selected: formatValues(this.validateNumber(zndivideDwOnStops / divid), 'dw'),
                zone_dwell_compared: formatValues(this.validateNumber(zndivideDwOnStopsB / divid), 'dw')
              };
            })
        };
      });
      this.rows = rows;
    },
    toggleExpand(row) {
      row.expand = !row.expand;
    },
    validateNumber(num) {
      return (!isFinite(num) || isNaN(num)) ? 0 : num;
      },
    exportToCSV() {
  let csvContent = 'data:text/csv;charset=utf-8,';
  
  // Get visible columns
  const visibleCols = this.columns.filter(col => col.visible);

  // Define headers
  csvContent += visibleCols.map(col => col.label).join(',') + '\n';
  
  this.rows.forEach(row => {
    csvContent += visibleCols.map(col => row[col.name] || '-').join(',') + '\n';
    
    // Include subZones
    if (row.subZones && row.subZones.length > 0) {
      row.subZones.forEach(subZone => {
        csvContent += visibleCols.map(col => subZone[col.name] || '-').join(',') + '\n';
      });
    }
  });

  // Download CSV
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute(
    'download',
    `${this.$store.state.user.translate.zone_kpi_overview} Selected_${this.getStartingPeriod}-${this.getEndingPeriod} Compared_${this.getStartingCompared}-${this.getEndingCompared}.csv`
  );
  document.body.appendChild(link);
  link.click();
},

exportToExcel() {
  let exportData = [];
  const visibleCols = this.columns.filter(col => col.visible);

  this.rows.forEach(row => {
    let rowData = {};
    visibleCols.forEach(col => {
      rowData[col.label] = row[col.name] || '-';
    });
    exportData.push(rowData);
    
    if (row.subZones && row.subZones.length > 0) {
      row.subZones.forEach(subZone => {
        let subZoneData = {};
        visibleCols.forEach(col => {
          subZoneData[col.label] = subZone[col.name] || '-';
        });
        exportData.push(subZoneData);
      });
    }
  });

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, this.$store.state.user.translate.zone_kpi_overview);
  XLSX.writeFile(workbook, `${this.$store.state.user.translate.zone_kpi_overview} Selected_${this.getStartingPeriod}-${this.getEndingPeriod} Compared_${this.getStartingCompared}-${this.getEndingCompared}.xlsx`);
}

  }
};
