
import Vue from 'vue';
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import LoadingSpinner from '@/components/Common/Loading/LoadingSpinner.vue';
import ChartPie from '@/components/Common/Chart/Pie/ChartPie.vue';
import ChartPieValue from '@/components/Common/Chart/Pie/ChartPieValue.vue';
import BoxHeader from '@/components/Box/Common/Header/BoxHeader.vue';
import { MULTI_COLORS,getTransMethod } from '@/util/colors';
import store from '@/store';

export default Vue.extend({
components: { LoadingSpinner, BoxHeader, ChartPie, ChartPieValue },
   // eslint-disable-next-line vue/require-prop-types
props:["zone"],
data() {
  return {
    loading: false,
    series: [],
    labels: [],
    colors: [],
    periodType: 'value',
  };
},
computed: {
  ...mapGetters('user', ['getLocale']),
  ...mapState('demographics', ['demoGender','demoGenderValue']),
},
watch: {
  zone: _.debounce(function () {
    this.loadData();
  }, 300),
  periodType: _.debounce(function () {
    this.loadData();
  }, 300)
},
methods:{
  loadData() {
    this.loading = true;
      localStorage.setItem("reload","0" );
      this.colors = [];
      this.seriesPer = [];
      this.series = [];
      
      this.labels = [];

      let data = [];
      if(this.periodType == 'percentage')
      {
      //   data = this.demoGender;
      const cCount = {};
        Object.values(this.zone || {}).forEach(({ summary: { dm } }) =>
          Object.entries(dm).forEach(([key, value]) => (cCount[key] = (cCount[key] || 0) + value))
        );
        const sum = ['1', '2', '3', '4']?.reduce((acc, key) => acc + (cCount[key] || 0), 0);
        const sumO = ['5', '6', '7', '8']?.reduce((acc, key) => acc + (cCount[key] || 0), 0);
        this.seriesPer = [sum, sumO];
        const totalVal = this.seriesPer.reduce((sum, value) => {
          return sum + (typeof value === 'number' && !isNaN(value) ? value : 0);
        }, 0);

        const percentages = totalVal > 0
          ? this.seriesPer.map(value => (typeof value === 'number' && !isNaN(value) ? value : 0) / totalVal * 100).map(p => parseFloat(p.toFixed(2)))
          : this.seriesPer.map(() => 0);

        this.seriesPer = percentages;
        this.series = null;
      }
      else
      {
      //   data = this.demoGenderValue;
      const cCount = {};
        Object.values(this.zone || {}).forEach(({ summary: { dm } }) =>
          Object.entries(dm).forEach(([key, value]) => (cCount[key] = (cCount[key] || 0) + value))
        );
        const sum = ['1', '2', '3', '4']?.reduce((acc, key) => acc + (cCount[key] || 0), 0);
        const sumO = ['5', '6', '7', '8']?.reduce((acc, key) => acc + (cCount[key] || 0), 0);
        this.series = [sum, sumO];
        this.seriesPer = null;
      }
      const storeState: any = store.state;
      const translate = storeState.user.translate;
      const id = storeState.user.profile.tenant._id;

      this.labels[0] = translate[`male_${id}`]?translate[`male_${id}`]:translate['male'];
      this.labels[1] = translate[`female_${id}`]?translate[`female_${id}`]:translate['female'];
      // this.colors = MULTI_COLORS;
      this.colors[0] = translate[`male_child_color_${id}`]?translate[`male_child_color_${id}`]:translate['male_child_color'];//MULTI_COLORS[0];//$store.state.user.translate.male_child_color;
      this.colors[1] =  translate[`female_child_color_${id}`]?translate[`female_child_color_${id}`]:translate['female_child_color'];//MULTI_COLORS[4];

    this.loading = false;
  }
}
});
